import React, { useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import noop from "@corefront-shared/utils/noop"
import { useFormikContext } from "formik"
import { colors } from "@corefront-shared/styled/theme"

export const Error = styled.div`
  color: ${colors(`error_text`)};
  background-color: ${colors(`error_bg`)};
  border: 1px solid ${colors(`error_border`)};
  border-radius: 4px;
  padding: 16px;
  margin-top: 9px;
`

const Info = styled.div`
  color: ${props => props.theme.colors.secondary};
`

export default function InfoAndError({ field: { name, value = `` }, renderInfo = noop }) {
  const { errors, touched } = useFormikContext()

  const info = useMemo(() => {
    return renderInfo(name, value)
  }, [renderInfo, name, value])

  if (!touched[name]) {
    return null
  }

  return (
    <>
      {info && <Info data-testid={`form_info_${name}`}>{info}</Info>}
      {errors[name] && <Error data-testid={`form_error_${name}`}>{errors[name]}</Error>}
    </>
  )
}

InfoAndError.propTypes = {
  renderInfo: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }).isRequired,
}
