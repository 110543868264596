import { useEffect, useMemo, useState } from "react"
import { useFormikContext } from "formik"

export function useFocus() {
  return useMemo(() => {
    return {
      name: `focus`,
      Component: Focus,
      props: {},
      isReady: true,
    }
  }, [])
}

const preventScroll = { preventScroll: false }

export default function Focus() {
  const { submitCount, isValid, status, errors, isValidating } = useFormikContext()
  const [lastSubmitCount, setLastSubmitCount] = useState(0)

  useEffect(() => {
    if (submitCount > lastSubmitCount && !isValid && !isValidating) {
      const { fieldRefs } = status
      const refs = fieldRefs.getRefs()

      for (const field of Object.keys(refs)) {
        if (errors[field] && refs[field].current) {
          refs[field].current.focus(preventScroll)
          setLastSubmitCount(submitCount)
          break
        }
      }
    }
  }, [submitCount, lastSubmitCount, isValid, isValidating, errors])

  return null
}
