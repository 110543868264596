import React from "react"
import PropTypes from "prop-types"
import Picto from "../picto.component"

export default function Medal({ color, ...props }) {
  return (
    <Picto viewBox="0 0 61.087 69.079" color={color} {...props}>
      <path
        d="M504.481 218.808l-10.845-23.547a24.049 24.049 0 10-39.191 0L443.6 218.81a1.112 1.112 0 001.258 1.548l8.434-1.936 3.374 7.334a1.11 1.11 0 001.009.648 1.116 1.116 0 001.009-.651l9.578-21.062a23.22 23.22 0 0011.444.029l9.69 21.039a1.11 1.11 0 002.019 0l3.376-7.334 8.432 1.934a1.111 1.111 0 001.258-1.547zm-46.813 3.811l-2.737-5.947a1.109 1.109 0 00-1.258-.619l-7.109 1.632 9.429-20.472a24.1 24.1 0 0010.122 6.836zm-5.454-41.248a21.826 21.826 0 1121.825 21.829 21.85 21.85 0 01-21.825-21.829zm42.193 34.682a1.106 1.106 0 00-1.258.619l-2.742 5.961-8.544-18.547a24.1 24.1 0 0010.224-6.874l9.429 20.472z"
        transform="translate(-443.497 -157.324)"
      />
      <path
        d="M468.908 160.132a18.994 18.994 0 1018.992 18.995 19.015 19.015 0 00-18.992-18.995zm0 35.765a16.771 16.771 0 1116.772-16.771 16.79 16.79 0 01-16.772 16.774z"
        transform="translate(-438.366 -155.079)"
      />
      <path
        d="M475.06 171.675h-6.649l-2.053-6.325a1.111 1.111 0 00-2.114 0l-2.055 6.325h-6.649a1.111 1.111 0 00-.653 2.01l5.378 3.908-2.053 6.325a1.111 1.111 0 001.709 1.242l5.378-3.908 5.38 3.908a1.111 1.111 0 001.709-1.242l-2.055-6.325 5.38-3.908a1.111 1.111 0 00-.655-2.01zm-6.685 4.594a1.113 1.113 0 00-.4 1.243l1.247 3.84-3.266-2.373a1.111 1.111 0 00-1.306 0l-3.266 2.373 1.247-3.84a1.11 1.11 0 00-.4-1.242l-3.266-2.372H463a1.113 1.113 0 001.056-.768l1.247-3.84 1.247 3.838a1.111 1.111 0 001.056.768h4.038z"
        transform="translate(-434.757 -151.522)"
      />
    </Picto>
  )
}

Medal.propTypes = {
  color: PropTypes.string,
}
