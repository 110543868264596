import {
  getPrice as sharedGetPrice,
  getStripeTokenFromUnderwriterId,
  getTestStripeTokenFromUnderwriterId,
} from "@corefront-shared/components/product/product.hooks"
import { useMemo } from "react"
import { formatDatetime } from "./time"
import { formatPrice } from "@corefront-shared/utils/format"

export function getPrice(product) {
  return product ? sharedGetPrice(product.variants[0], product.invoiceRecurrence) : sharedGetPrice(null)
}

export function usePrice(product) {
  return useMemo(() => getPrice(product), [product])
}

export function usePriceMention(product, brandName, insertDate = undefined) {
  const { betterPrice, price: brutPrice, standardPrice: brutStandardPrice, dateToApply } = usePrice(product)
  if (!product) {
    return null
  }

  const price = formatPrice(brutPrice)
  const standardPrice = formatPrice(brutStandardPrice)
  const endDate = insertDate ? new Date(insertDate) : new Date()
  endDate.setDate(endDate.getDate() + 30)
  const endPriceValidityDate = formatDatetime(endDate)
  const campaignEndPriceValidityDate = formatDatetime(dateToApply)

  if (betterPrice) {
    return `*Tarif préférentiel de ${price}€ pour la 1ère année de souscription si vous souscrivez jusqu'au ${campaignEndPriceValidityDate} inclus, au lieu du tarif de référence de ${standardPrice}€. Offre de souscription valable jusqu'au ${endPriceValidityDate}`
  }

  return `*Tarif de ${price}€ pour la 1ère année de souscription. Offre de souscription valable jusqu'au ${endPriceValidityDate}`
}

export const getTokenFromUnderwriterId = productCoverit => {
  const { underwriterId } = productCoverit || {}

  if (process.env.ENVIRONMENT === `prod`) {
    return getStripeTokenFromUnderwriterId(underwriterId)
  }

  return getTestStripeTokenFromUnderwriterId(underwriterId)
}
