// Cannot import api-fetcher, weird error webpack
import { FetchApiError } from "@homeserve/api-fetcher"
import { sentryCaptureException } from "@corefront-shared/utils/sentry"

const { fetchApiCore } = require("@homeserve/api-fetcher")

export function isApiErrorCode(apiError, code) {
  return apiError.response?.errors?.find(responseError => responseError.message === code)
}

const fetchWrapper = fetchApiCore({
  url: APP_CONFIG.apis.wrapper.url,
  token: APP_CONFIG.apis.wrapper.token,
})

const fetchWrapperGraphql = (token, query) =>
  fetchWrapper("/graphql", {
    method: `POST`,
    headers: {
      [`x-tokensf`]: token,
    },
    body: JSON.stringify({ query }),
  }).then(response => {
    // Map wrapper error into FetchApiError here, until we do this in api-fetcher
    if (response?.errors) {
      const error = new FetchApiError(
        {
          url: APP_CONFIG.apis.wrapper.url + "/graphql",
          method: `POST`,
        },
        response,
      )

      sentryCaptureException(error)

      throw error
    }

    return response
  })

export const isHomeServePartner = policy => policy.partner === `DO`

export const getYouSignLink = token =>
  fetchWrapperGraphql(
    token,
    `
      query getGetSignDocumentLink {
          getSignDocumentLink(policyId: "42") {
          link
      }}
    `,
  ).then(response => response.data.getSignDocumentLink)

export const getEntities = token =>
  fetchWrapperGraphql(
    token,
    `
      query getLegacyEntities {
          legacyEntities(policyId: "42", customerId: "42", coveritId: "42") {
              policy {
                  status
                  partner
                  insertDate
                  partnerVerbose
                  partnerLines {
                      commercial
                  }
                  payment {
                    iban
                    accountName
                    typeCode
                  }
              }
              customer {
                  title
                  lastName
                  firstName
                  email
                  phones {
                      type
                      number
                  }
                  addresses {
                      type
                      streetNo
                      street
                      flatNo
                      residence
                      additionalAddress
                      postCode
                      town
                  }
                  housing {
                      occupancyStatus
                  }
              }
              productCoverit {
                  marketingMention
                  commercialDesignation
                  underwriterId
                  variants {
                      offerCode
                      areaCode
                      offerPrice
                      standardPrice
                      campaignEndDate
                  }
              }
          }
      }
    `,
  )

export const updateIban = (token, type, iban, accountName) =>
  fetchWrapperGraphql(
    token,
    `
      mutation updateLegacyPolicyIban {
          updateLegacyPolicy(id: "42", legacyPolicyUpdate: { payment: {type:"${type}", iban:"${iban}", accountName: "${accountName}" }} ) {
              payment {
                iban
                accountName
                typeCode
              }
          }
      }
    `,
  ).then(fetchResult => fetchResult.data.updateLegacyPolicy)

export const updateCard = (token, transactionId) =>
  fetchWrapperGraphql(
    token,
    `
      mutation updateLegacyPolicyCard {
          updateLegacyPolicy(id: "42", legacyPolicyUpdate: { payment: {type:"CCC", transactionId:"${transactionId}" }} ) {
              payment {
                transactionId
                typeCode
              }
          }
      }
    `,
  ).then(fetchResult => fetchResult.data.updateLegacyPolicy)

const fetchPayment = fetchApiCore({
  url: APP_CONFIG.apis.payment.url,
  token: APP_CONFIG.apis.payment.token,
})

export const getPaymentIntentToken = (captchaToken, underwriterId, amount, metadata = {}) =>
  fetchPayment("/paymentIntents", {
    method: `POST`,
    headers: {
      [`x-captcha-token`]: captchaToken,
    },
    body: JSON.stringify({
      underwriterId,
      amount,
      metadata,
    }),
  })
