import React from "react"
import { Link } from "react-router-dom"
import BannerSeparator from "../components/banner/bannerSeparator.component"
import Section from "@corefront-shared/components/section.component"
import OupsBlock from "../components/oupsBlock/oupsBlock.component"

export default function Page404() {
  return (
    <>
      <BannerSeparator />
      <Section padding data-testid="data_error_page">
        <OupsBlock>
          Cette page n’existe pas 🙁.
          <br />
          <Link to="/" replace data-testid="button_404">
            retour à la page d'accueil
          </Link>
        </OupsBlock>
      </Section>
    </>
  )
}
