import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { useFormikContext } from "formik"
import MaskedInput from "react-text-mask"

import { colors, useThemeContext } from "@corefront-shared/styled/theme"
import { flexCenter } from "@corefront-shared/styled/styles"
import noop from "@corefront-shared/utils/noop"
import { noopMask } from "@corefront-shared/utils/validate"

import InfoAndError from "./infoAndError.component"
import Label from "./label.component"
import { StyledField } from "./styledField.component"
import { Helper } from "./helper.component"
import Notice from "./notice.component"
import Warning from "../pictos/warning.component"

export const InputCss = css`
  display: block;
  width: 100%;
  color: ${colors(`grey.g800`)};
  padding: 12px;
  line-height: 1.5;
  outline: none;
  border-radius: 4px;
  border: 2px solid
    ${props => {
      if (props.isTouched) {
        if (props.hasError) {
          return colors(`error`)
        }

        return colors(`valid`)
      }

      return colors(`grey.g500`)
    }};

  &:focus {
    outline: none;
    border: 2px solid rgb(0, 150, 200);
  }
`

export const Input = styled(({ hasError, isTouched, innerRef, reversedTheme, ...props }) => (
  <MaskedInput
    {...props}
    render={(textMaskRef, propsInput) => (
      <input
        {...propsInput}
        ref={node => {
          textMaskRef(node)
          innerRef.current = node
        }}
      />
    )}
  />
))`
  ${InputCss}
`

const InputContainer = styled.div`
  position: relative;
`

const PictoContainer = styled.div`
  ${flexCenter};
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
`

function InputPicto({ onError, Picto }) {
  const {
    colors: {
      error,
      grey: { g600: grey },
    },
  } = useThemeContext()

  if (onError) {
    return (
      <PictoContainer>
        <Warning color={error} size={25} />
      </PictoContainer>
    )
  }

  if (Picto) {
    return (
      <PictoContainer>
        <Picto color={grey} size={25} />
      </PictoContainer>
    )
  }

  return null
}

InputPicto.propTypes = {
  onError: PropTypes.bool.isRequired,
  Picto: PropTypes.elementType,
}

export default function InputText({
  id,
  placeholder,
  label = ``,
  help = ``,
  notice = ``,
  renderInfo,
  field,
  Picto = null,
  mask: { mask, unmask } = noopMask,
  customRender,
  maxLength = 255,
  guide = true,
  innerRef = noop,
}) {
  const { setFieldValue, handleBlur, handleChange, errors, touched } = useFormikContext()
  const { name, value } = field
  const labelComponent = label && <Label htmlFor={id}>{label}</Label>
  const helperComponent = <Helper help={help} />
  const noticeComponent = <Notice data-testid={`form_notice_${name}`} text={notice} />
  const isTouched = touched[name] || false
  const hasError = !!errors[name] || false

  const fieldComponent = (
    <InputContainer>
      <Input
        mask={mask}
        guide={guide}
        placeholder={placeholder}
        data-testid={`form_field_${name}`}
        name={name}
        value={value}
        id={id}
        innerRef={innerRef}
        type="text"
        onChange={event => {
          const unmaskedValue = unmask(event.target.value)
          handleChange({ ...event, target: { ...event.target, name, id, value: unmaskedValue } })
          setFieldValue(name, unmaskedValue)
        }}
        onBlur={handleBlur}
        hasError={hasError}
        isTouched={isTouched}
        maxLength={maxLength}
      />
      <InputPicto onError={isTouched && hasError} Picto={Picto} />
    </InputContainer>
  )

  const infoErrorComponent = <InfoAndError field={field} renderInfo={renderInfo} />

  const render = customRender ? (
    customRender({
      label: labelComponent,
      help: helperComponent,
      notice: noticeComponent,
      field: fieldComponent,
      infoError: infoErrorComponent,
    })
  ) : (
    <>
      {labelComponent}
      {helperComponent}
      {noticeComponent}
      {fieldComponent}
      {infoErrorComponent}
    </>
  )

  return <StyledField flex>{render}</StyledField>
}

InputText.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  mask: PropTypes.object,
  help: PropTypes.string,
  Picto: PropTypes.func,
  notice: PropTypes.string,
  placeholder: PropTypes.string,
  renderInfo: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }).isRequired,
  customRender: PropTypes.func,
  maxLength: PropTypes.number,
  guide: PropTypes.bool,
  innerRef: PropTypes.object,
}
