import React, { useState } from "react"
import styled from "styled-components"
import { colors, fontSizes, useThemeContext } from "@corefront-shared/styled/theme"
import { useAppContext } from "../providers/app.provider"
import { usePriceMention } from "../../utils/product"
import { ProductPriceMemo } from "./productPrice.component"
import breakpoint from "styled-components-breakpoint"
import { Arrow } from "./arrow.component"
import CircleCheck from "@corefront-shared/components/pictos/circleChecked.component"
import { firstLetterUppercase } from "@corefront-shared/utils/format"
import { Empty } from "@corefront-shared/components/empty.component"

const Container = styled.div`
  border: 1px solid ${colors("primary")};
  border-radius: 10px;
  width: 100%;
  padding: 16px 20px 10px;
  cursor: pointer;
  ${breakpoint(`medium`)`
    cursor: auto;
  `};
`

const Head = styled.div`
  display: none;
  ${breakpoint(`medium`)`
    display: block
  `};
`
const HeadTitle = styled.div``
const HeadSeparator = styled.div`
  width: 94px;
  border-radius: 5px;
  border: 2px solid ${colors("primary")};
  margin: 8px 0;
`

const Product = styled.div``
const ProductHead = styled.div`
  display: flex;
  justify-content: space-between;
`

const ProductChevron = styled.div`
  color: ${colors(`primary`)};
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
  font-size: ${props => props.fontSize};
  ${breakpoint(`medium`)`
    display: none
  `};
`
const ProductTitle = styled.div`
  font-weight: 900;
`
const PictoContainer = styled.div``

const ProductDescriptionText = styled.div`
  margin-left: 8px;
`
const ProductDescriptionItem = styled.div`
  display: flex;
`
const ProductDescription = styled.div`
  display: ${props => (props.isOpen ? `block` : `none`)};
  ${breakpoint(`medium`)`
    display: block;
    margin: 11px 0px;
  `};
`
const ProductMentions = styled.div`
  color: ${colors("grey.g700")};
  display: ${props => (props.isOpen ? `block` : `none`)};
  font-size: ${fontSizes(`small`)};
  ${breakpoint(`medium`)`
    display: block
  `};
`

export default function ProductBlock() {
  const { state } = useAppContext()
  const { colors: themeColors } = useThemeContext()
  const [isOpen, setIsOpen] = useState(false)
  const userData = state.userData || {}

  const { productCoverit, policy } = userData || {}
  const marketingMention = productCoverit?.marketingMention || ``
  const priceMention = usePriceMention(productCoverit, policy?.partnerVerbose, policy?.insertDate)

  return (
    <Container onClick={() => setIsOpen(!isOpen)}>
      <Head>
        <HeadTitle data-testid="product_block_title">Votre contrat d'assistance</HeadTitle>
        <HeadSeparator />
      </Head>
      {productCoverit && (
        <Product>
          <ProductHead>
            <ProductTitle data-testid="product_title">{productCoverit.commercialDesignation}</ProductTitle>
            <ProductChevron isOpen={isOpen}>
              <Arrow color={"black"} down isOpened={isOpen} />
            </ProductChevron>
          </ProductHead>
          <ProductPriceMemo product={productCoverit} />
          <ProductDescription data-testid="product_description" isOpen={isOpen}>
            {marketingMention.split(`\n`).map((item, i) => {
              return item ? (
                <ProductDescriptionItem key={i}>
                  <PictoContainer>
                    <CircleCheck size={23} color={"white"} color2={themeColors.valid} color3={themeColors.valid} />
                  </PictoContainer>
                  <ProductDescriptionText>{firstLetterUppercase(item)}</ProductDescriptionText>
                </ProductDescriptionItem>
              ) : (
                <Empty />
              )
            })}
          </ProductDescription>
          <ProductMentions data-testid="product_mention" isOpen={isOpen}>
            {priceMention}
          </ProductMentions>
        </Product>
      )}
    </Container>
  )
}
