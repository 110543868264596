import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { condition, notCondition, toPx } from "@corefront-shared/styled/theme"
import breakpoint from "styled-components-breakpoint"

const maxWidth = prop => props => toPx(props.theme.maxWidth[props[prop]]) || `100%`

const maxWidthStyle = css`
  position: relative;
  max-width: ${maxWidth(`contentWidth`)};
  width: 100%;
  margin: 0 auto;
`

const Background = styled.section.attrs(props => ({
  padding: props.padding ? `24px` : 0,
}))`
  position: relative;
  background-color: ${props => (props.bgImageMobile && props.bgImage ? `black` : props.bgColor)};
  margin: 0 auto;
  padding: ${props => props.padding} ${props => (props.bgFullWidthMobile ? 0 : `20px`)};
  width: 100%;
  max-width: ${maxWidth(`bgWidth`)};
  ${props => props.styles};

  ${breakpoint(`small`)`
    padding: ${props => props.padding} ${props => (props.bgFullWidthMobile ? 0 : `30px`)};
    background-color: ${props => (props.bgImage ? `black` : props.bgColor)};
  `}

  ${breakpoint(`medium`)`
    padding: ${props => props.padding} 0;
  `}

  ${condition(`bevel`)`
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 24px), 0% 100%);

    ${breakpoint(`medium`)`
      ${notCondition(`bevelDesktop`)`
        clip-path: none;
      `};
    `}
  `};
`

export const SectionImage = styled.div`
  display: ${condition(`bgImageMobile`, `block`, `none`)} !important;
  position: absolute !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;

  ${breakpoint(`small`)`
    display: block !important;
  `}

  ${breakpoint(`medium`)`
    opacity: 1;
  `}
`

const MaxWidth = styled.div`
  ${maxWidthStyle};
`

const BackgroundMaxWidth = styled(Background)`
  ${maxWidthStyle};
`

function Section({
  children,
  as = `section`,
  bgColor,
  bgImage,
  bgImageMobile = !!bgImage,
  bgWidth = `medium`,
  contentWidth = `medium`,
  padding = false,
  bgFullWidthMobile = false,
  id = undefined,
  bevel = false,
  bevelDesktop = false,
  styles,
  ImageComponent,
}) {
  // We can squash background and maxWidth components
  if (bgWidth === contentWidth && !bgImage) {
    return (
      <BackgroundMaxWidth
        data-testid={arguments[0][`data-testid`] || undefined}
        as={as}
        id={id}
        bgColor={bgColor}
        bgWidth={bgWidth}
        padding={padding}
        bgFullWidthMobile={bgFullWidthMobile}
        contentWidth={contentWidth}
        bevel={bevel}
        bevelDesktop={bevelDesktop}
        styles={styles}
      >
        {children}
      </BackgroundMaxWidth>
    )
  }

  return (
    <Background
      data-testid={arguments[0][`data-testid`] || undefined}
      as={as}
      id={id}
      bgColor={bgColor}
      bgWidth={bgWidth}
      padding={padding}
      bgFullWidthMobile={bgFullWidthMobile}
      bgImage={bgImage}
      bgImageMobile={bgImageMobile}
      bevel={bevel}
      bevelDesktop={bevelDesktop}
      styles={styles}
    >
      {!!(bgImage && ImageComponent) && (
        <SectionImage as={ImageComponent} file={bgImage} bgImageMobile={bgImageMobile} objectPosition="50% 50%" />
      )}
      <MaxWidth contentWidth={contentWidth}>{children}</MaxWidth>
    </Background>
  )
}

Section.propTypes = {
  as: PropTypes.string,
  id: PropTypes.string,
  bgColor: PropTypes.string,
  bgImage: PropTypes.object,
  bgImageMobile: PropTypes.bool,
  bgWidth: PropTypes.oneOf([`full`, `large`, `medium`]),
  contentWidth: PropTypes.oneOf([`full`, `large`, `medium`]),
  padding: PropTypes.bool,
  bgFullWidthMobile: PropTypes.bool,
  bevel: PropTypes.bool,
  bevelDesktop: PropTypes.bool,
  styles: PropTypes.array,
  ImageComponent: PropTypes.elementType,
  children: PropTypes.any,
}

export default Section
