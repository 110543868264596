import { createGlobalStyle, css } from "styled-components"
import { colors } from "@corefront-shared/styled/theme"
import styledNormalize from "styled-normalize"

export default createGlobalStyle`
  ${() =>
    process.env.ENVIRONMENT === `mock` &&
    window.puppeteer &&
    css`
      * {
        transition: none !important;
        animation: none !important;
      }
    `}
  ${styledNormalize}

  html, body {
    margin: 0;
    font-family: ${props => props.theme.fonts.primary.family};
    font-size: ${props => props.theme.fonts.sizes.regular};
    color: ${colors(`grey.g800`)};
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  /* css trick to target only ie11 and fix footer on top */
  /* stylelint-disable-next-line selector-type-no-unknown */
  _:-ms-fullscreen, :root #root {
    display: block;
    flex-direction: unset;
    min-height: unset;
  }

  iframe {
    max-width: 100%;
  }

  ul {
    margin: 0;
  }

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  .grecaptcha-badge {
    z-index: 100;
  }

  b, strong {
    font-weight: 900;
  }
`
