import React from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { condition } from "@corefront-shared/styled/theme"
import Section from "@corefront-shared/components/section.component"
import PropTypes from "prop-types"
import FrenchFlag from "@corefront-shared/components/pictos/frenchFlag.component"
import PhoneBox from "../phone/phoneBox.component"

const HsvListen = styled.span`
  font-weight: 900;
  padding-left: 4px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${breakpoint(`small`)`
      padding: 20px 0;
      flex-direction: row;
      align-items: center;
  `};
`

export const Col = styled.div`
  flex: 1;
  display: flex;
  padding: 6px 8px;
  align-items: ${condition(`top`, `flex-start`, `center`)};

  ${breakpoint(`medium`)`
    margin-bottom: 0;
    justify-content: ${condition(`center`, `center`, `left`)};
  `}
`

export function FooterFrenchSection({ phoneNumber = "" }) {
  return (
    <Section bgWidth="full" data-testid="french_section">
      <Row>
        <Col center={!phoneNumber}>
          <FrenchFlag size={32} />
          <HsvListen> HomeServe est à votre écoute depuis Lyon</HsvListen>
        </Col>
        {phoneNumber && (
          <>
            <Col center>
              <PhoneBox phoneNumber={phoneNumber} />
            </Col>
            <Col> du lundi au vendredi de 8h à 19h et le samedi de 9h à 12h.</Col>
          </>
        )}
      </Row>
    </Section>
  )
}

FooterFrenchSection.propTypes = {
  phoneNumber: PropTypes.string,
}
