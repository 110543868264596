import React from "react"
import styled from "styled-components"
import { colors, fontSizes } from "@corefront-shared/styled/theme"
import Oups from "@corefront-shared/components/pictos/oups.component"
import PropTypes from "prop-types"
import breakpoint from "styled-components-breakpoint"
import { flexCenter } from "@corefront-shared/styled/styles"

const Title = styled.span`
  color: ${colors(`primary`)};
  font-weight: 900;
  font-size: ${fontSizes(`x_large`)};
  padding-top: 31px;
`

const Description = styled.div`
  text-align: center;
  color: ${colors(`grey.g800`)};
`

const Block = styled.div`
  ${flexCenter};
  flex-direction: column;
  margin: 40px auto 30px;
  max-width: 600px;
  min-height: 324px;
  padding: 10px 30px 20px;
  border-radius: 8px;
  border: solid 1px ${colors(`grey.g500`)};
  ${breakpoint(`small`)`
    padding: 55px 30px 40px;
    margin: 100px auto;
  `}
`

export default function OupsBlock({ title = "Oups !", children }) {
  return (
    <Block>
      <div>
        <Oups size={100} color="red" />
      </div>
      <Title>{title}</Title>
      <Description>{children}</Description>
    </Block>
  )
}

OupsBlock.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
}
