import React from "react"
import styled from "styled-components"

const Mock = styled.div`
  display: flex;
  width: 170px;
  height: 170px;
  vertical-align: middle;
  background-color: white;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`

export default function VerifiedReviews() {
  if (process.env.ENVIRONMENT === `mock`) {
    return <Mock>Avis vérifiés</Mock>
  }

  return (
    <img
      src="https://cl.avis-verifies.com/fr/cache/e/5/7/e57d3166-b99f-01d4-e122-5fb1008776f9/widget3/widget19-29440.png"
      alt="Avis clients homeserve.fr"
      width="170"
    />
  )
}
