import React from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Proptypes from "prop-types"
import { colors } from "@corefront-shared/styled/theme"
import Phone from "@corefront-shared/components/pictos/phone.component"
import Form from "@corefront-shared/components/form/form.component"
import Field from "@corefront-shared/components/form/field.component"
import { Error } from "@corefront-shared/components/form/infoAndError.component"
import InputText from "@corefront-shared/components/form/inputText.component"
import { useFocus } from "@corefront-shared/components/form/plugins/focus.component"

const InputContainer = styled.div`
  position: relative;

  &::placeholder {
    color: ${props => props.theme.colors.grey.g600};
    font-style: italic;
    font-size: ${props => props.theme.fonts.sizes.regular};
  }

  input {
    height: 50px;
    width: 100%;
    padding-left: 10px;
    background-color: ${props => props.theme.colors.grey.g300};

    border: 1px solid ${colors(`primary`)};
    border-radius: 2px;
    outline: 0;
  }

  ${breakpoint(`small`)`
    display: block;
    max-width: 300px;
    margin: 0 auto 5px auto;
  `}
`

const initialValues = {
  phone: ``,
}

export default function WebCallbackForm({
  onSubmitWcb,
  onSubmitSuccess,
  onSubmitFail,
  errorBack,
  captcha,
  t,
  disabled,
  validator,
  submitButton,
}) {
  const focus = useFocus()
  const SubmitButton = submitButton

  return (
    <Form
      name="webcallback"
      initialValues={initialValues}
      captcha={captcha}
      focus={focus}
      onSubmit={onSubmitWcb}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFail={onSubmitFail}
      validate={validator.validate}
      t={t}
    >
      <InputContainer>
        <Field
          component={InputText}
          type="tel"
          name="phone"
          mask={validator.telMask}
          Picto={Phone}
          placeholder={t(`components:webcallback.form_phone_label`) + `*`}
        />
      </InputContainer>
      {errorBack && (
        <Error data-testid="webcallback_modal_error_server">{t(`components:webcallback.form_error`)}</Error>
      )}
      <SubmitButton data-testid="webcallback_modal_submit" disabled={disabled}>
        {t(`components:webcallback.form_submit`)}
      </SubmitButton>
    </Form>
  )
}

WebCallbackForm.propTypes = {
  errorBack: Proptypes.bool.isRequired,
  onSubmitWcb: Proptypes.func.isRequired,
  onSubmitSuccess: Proptypes.func.isRequired,
  onSubmitFail: Proptypes.func.isRequired,
  captcha: Proptypes.shape({
    onSubmit: Proptypes.func.isRequired,
    onSubmitFail: Proptypes.func.isRequired,
  }),
  submitButton: Proptypes.object.isRequired,
  t: Proptypes.func.isRequired,
  disabled: Proptypes.bool.isRequired,
  validator: Proptypes.shape({
    telMask: Proptypes.object.isRequired,
    validate: Proptypes.func.isRequired,
  }).isRequired,
}
