import React from "react"
import PropTypes from "prop-types"
import Picto from "../picto.component"

export default function Truck({ color, size = 100, ...props }) {
  return (
    <Picto viewBox="38 -10 28.087 58.079" width={size * 2} height={size} color={color} {...props}>
      <g>
        <path
          d="M125.887 182.726l-9.053-5.6a8.106 8.106 0 01-1.865-1.742 50.695 50.695 0 01-2.106-2.643l-.843-.843-.012-.012-.01-.013a57.911 57.911 0 00-6.962-7.5 13.5 13.5 0 00-8.713-3.581h-41.84a4.61 4.61 0 00-4.8 4.806v3.391H34.21a1.356 1.356 0 000 2.708h25.881a1.356 1.356 0 000-2.708h-7.7v-3.391a2.342 2.342 0 012.1-2.528h41.833a12.2 12.2 0 017.786 3.267 49.835 49.835 0 016.3 6.783l.846.843.013.015.013.016c1.717 2.153 3.459 4.306 4.661 5.1l9.084 5.624.031.03a2.614 2.614 0 01.972 1.988v3.391H52.387v-6.349h7.272a1.356 1.356 0 000-2.709H42.837a2.465 2.465 0 00-1.787 1.787 1.492 1.492 0 001.35 1.344h7.272v10.724a5.2 5.2 0 004.806 4.806h4.649l.034.334a7.661 7.661 0 007.826 7 8.367 8.367 0 007.827-7l.037-.329h29.568l.033.334a7.663 7.663 0 007.827 7 8.37 8.37 0 007.827-7l.037-.329h2.919a4.611 4.611 0 004.806-4.806v-8.2a4.952 4.952 0 00-1.981-4.008zm-66.738 13.608l-.085.254h-4.581a2.338 2.338 0 01-2.1-2.527v-2.1h9.792l-1.019.68c-1.132.743-1.577 2.373-2.007 3.693zm7.844 7.587a5.406 5.406 0 115.548-5.547 5.615 5.615 0 01-5.549 5.547zm39.562-11.38c-.87 1.291-1.674 2.552-1.663 3.675v.371h-29.54l-.085-.254a17.193 17.193 0 00-2.113-3.793l-.386-.577h34.17zm6.161 11.38a5.547 5.547 0 010-11.095 5.938 5.938 0 015.548 5.548 5.615 5.615 0 01-5.549 5.547zm12.881-9.86a2.339 2.339 0 01-2.1 2.527h-2.856l-.085-.254a17.133 17.133 0 00-2.113-3.793l-.384-.577h7.541z"
          transform="translate(-32.855 -160.786)"
        />
      </g>
      <path
        d="M68.779 172.658a1.357 1.357 0 00-.923-2.279H34.21a1.356 1.356 0 000 2.709h33.646a1.369 1.369 0 00.923-.43z"
        transform="translate(-32.855 -156.145)"
      />
      <path
        d="M76.67 165.172a1.3 1.3 0 00-.89-.607 1.491 1.491 0 00-1.355 1.355v7.333a1.488 1.488 0 001.355 1.355h6.471a5.84 5.84 0 014.144 1.834 1.812 1.812 0 002.063 0 1.655 1.655 0 000-1.632 8.141 8.141 0 00-5.777-2.479h-5.547v-5.978a2.2 2.2 0 00-.464-1.181z"
        transform="translate(-12.742 -158.958)"
      />
    </Picto>
  )
}

Truck.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}
