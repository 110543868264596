import React, { useState } from "react"
import styled from "styled-components"
import Proptypes from "prop-types"
import WebCallbackForm from "@corefront-shared/components/webCallback/webCallbackForm.component"

import { useTranslation } from "react-i18next"
import { Button } from "@corefront-shared/components/button.component"
import { useCaptcha } from "../captcha/captcha.component"
import { postContactWcb } from "../../data/contact.api"
import { compose, required, tel, telMask } from "../../utils/validate"

const Submit = styled(Button).attrs(() => ({
  textColor: "white",
  backgroundColor: props => props.theme.colors.primary,
  type: "submit",
}))`
  display: inline-flex;
`

function validate(values) {
  return {
    phone: compose(required, tel)(values.phone),
  }
}

export default function WebCallbackFormStatic({ onSubmit, errorBack, partnerCode }) {
  const { t } = useTranslation()
  const [disabled, setDisabled] = useState(false)
  const captcha = useCaptcha(true)
  const validator = {
    validate: validate,
    telMask: telMask,
  }

  async function onSubmitWcb({ captchaToken, ...values }) {
    setDisabled(true)

    return postContactWcb(values.phone, partnerCode, captchaToken)
  }

  function onSubmitSuccess(values, actions, data) {
    onSubmit(data.result === `OK` ? `success` : `error`)
    setDisabled(false)
  }

  function onSubmitFail(values, actions, error) {
    // sentryCaptureException(error)

    onSubmit(`error`)
    setDisabled(false)
  }

  return (
    <WebCallbackForm
      t={t}
      disabled={disabled}
      captcha={captcha}
      errorBack={errorBack}
      onSubmitFail={onSubmitFail}
      onSubmitSuccess={onSubmitSuccess}
      validator={validator}
      onSubmitWcb={onSubmitWcb}
      submitButton={Submit}
    />
  )
}

WebCallbackFormStatic.propTypes = {
  onSubmit: Proptypes.func.isRequired,
  errorBack: Proptypes.bool.isRequired,
  partnerCode: Proptypes.string,
}
