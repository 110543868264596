import React, { useContext, useEffect, useMemo, useReducer, useRef } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { getEntities, getYouSignLink } from "../../data/api"
import { useQueryParam, StringParam } from "use-query-params"
import { routes } from "../../routes/routes"
import { useSessionStorage } from "@homeserve/react-storage-provider"

export const StepperContext = React.createContext()

const initialState = {
  route: null,
  loadingData: true,
  loadingToken: true,
  userData: null,
  userDataError: null,
  currentStep: -1,
  availableSteps: [],
  steps: [
    {
      route: routes.home,
      title: "Votre offre",
      availableSteps: [0],
    },
    {
      route: routes.stepResume,
      title: "Récapitulatif",
      availableSteps: [0, 1],
    },
    {
      route: routes.stepPayment,
      title: "Paiement",
      availableSteps: [0, 1, 2],
    },
    {
      route: routes.stepSignature,
      title: "Signature",
      availableSteps: [0, 1, 2, 3],
    },
  ],
}

const reducer = (state, action) => {
  switch (action.type) {
    case `USER_DATA_ERROR`:
      return {
        ...state,
        currentStep: -1,
        route: routes.dataError,
        loadingData: false,
        userData: null,
        userDataError: action.error,
      }
    case `USER_DATA_LOADED`:
      return {
        ...state,
        loadingData: false,
        userData: action.data,
        userDataError: null,
      }
    case `POLICY_ALREADY_STARTED`:
      return {
        ...state,
        currentStep: -1,
        route: routes.policyStarted,
      }
    case `SWITCH_STEP`:
      return {
        ...state,
        route: state.steps[action.step].route,
        currentStep: action.step,
        availableSteps: state.steps[action.step].availableSteps,
      }
    case `UPDATE_POLICY_PAYMENT`:
      return {
        ...state,
        userData: {
          ...state.userData,
          policy: {
            ...state.userData.policy,
            payment: {
              ...state.userData.policy.payment,
              ...action.payment,
            },
          },
        },
      }
    case `SIDE_ROUTE`:
      return {
        ...state,
        currentStep: -1,
        route: action.route,
      }

    case `SYNC_TOKEN`:
      return {
        ...state,
        loadingToken: false,
      }
    default:
      return state
  }
}

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()
  const { push, location, action } = history
  const [token, setToken] = useQueryParam(`token`, StringParam)
  const { write, read } = useSessionStorage()
  const lastState = useRef()
  lastState.current = state

  // Handle synchronize state and history
  useEffect(() => {
    const _state = lastState.current
    const stepIndex = _state.steps.findIndex(step => step.route.path === location.pathname)

    if (action === `POP` || (action === `PUSH` && _state === initialState) || action === `REPLACE`) {
      if (stepIndex >= 0) {
        dispatch({ type: `SWITCH_STEP`, step: stepIndex })
      } else {
        const route = Object.keys(routes).find(routeName => routes[routeName].path === location.pathname)
        dispatch({ type: `SIDE_ROUTE`, route: routes[route] || routes["404"] })
      }
    }
  }, [location.pathname, action])

  // Sync token in url and storage
  useEffect(() => {
    if (token) {
      write("token", token)
    } else {
      const storageToken = read("token")

      if (storageToken) {
        setToken(storageToken)
      }
    }

    dispatch({ type: `SYNC_TOKEN` })
  }, [token])

  // Handle routing
  useEffect(() => {
    if (state.route) {
      push(state.route.path + location.search)
    }
  }, [state.route])

  // Handle user data
  useEffect(() => {
    if (state.loadingData && !state.loadingToken) {
      if (!token) {
        return dispatch({ type: `USER_DATA_ERROR`, error: `No token.` })
      }
      getEntities(token).then(
        fetchResult => {
          const { policy, customer, productCoverit } = fetchResult?.data?.legacyEntities

          if (!policy || !customer || !productCoverit || productCoverit.variants.length === 0) {
            return dispatch({ type: `USER_DATA_ERROR`, error: `Missing data.` })
          }

          // Simplify data for rendering
          fetchResult.data.legacyEntities.customer = {
            ...customer,
            mobilePhone: customer.phones.find(phone => phone.type === `mobile`)?.number || ``,
            homePhone: customer.phones.find(phone => phone.type === `house`)?.number || ``,
            housingAddress: customer.addresses.find(addr => addr.type !== `invoice`),
            billingAddress: customer.addresses.find(addr => addr.type === `invoice`),
          }

          dispatch({ type: `USER_DATA_LOADED`, data: fetchResult.data.legacyEntities })

          if (policy.status === `c`) {
            dispatch({ type: `POLICY_ALREADY_STARTED` })
          }
        },
        fetchError => {
          return dispatch({ type: `USER_DATA_ERROR`, error: fetchError.message })
        },
      )
    }
  }, [token, state.loadingData, state.loadingToken])

  const providedValue = useMemo(
    () => ({
      state,
      token,
      getYousignUrl: () => {
        return getYouSignLink(token)
      },
      updatePolicyPayment: payment => {
        dispatch({ type: `UPDATE_POLICY_PAYMENT`, payment })
      },
      setCurrentStep: stepId => {
        dispatch({ type: `SWITCH_STEP`, step: stepId })
      },
      setNextStep: () => {
        const step = state.currentStep + 1

        if (step < state.steps.length) {
          dispatch({ type: `SWITCH_STEP`, step })
        }
      },
      setPreviousStep: () => {
        const step = state.currentStep - 1

        if (step >= 0) {
          dispatch({ type: `SWITCH_STEP`, step })
        }
      },
    }),
    [state],
  )

  return <StepperContext.Provider value={providedValue}>{children}</StepperContext.Provider>
}

export const useAppContext = () => {
  return useContext(StepperContext)
}

AppProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
