import slugifyLib from "slugify"

function priceForXMonth(price = 0, month = 12) {
  return Math.round((price / month) * 100) / 100
}

export function formatPrice(price, month = 1) {
  const priceString = String(priceForXMonth(price, month)).replace(`.`, `,`)

  if (/,\d{1}$/.test(priceString)) {
    return priceString + `0`
  }

  return priceString
}

export function formatPriceAnnually(price) {
  return formatPrice(price, 1)
}

export function formatPriceMonthly(price) {
  return formatPrice(price, 12)
}

export function firstLetterUppercase(value) {
  if (!value) {
    return ``
  }

  return value[0].toUpperCase() + value.substr(1)
}

export function slugify(str) {
  // Convert special  accents, swap ñ for n, etc
  const from = `ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;`
  const to = `AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------`

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), `g`), to.charAt(i))
  }

  return slugifyLib(str, {
    lower: true,
    strict: true,
  })
}

export function inlineTemplateString(templateString) {
  return templateString.replace(/\n/gm, ``)
}

export function formatPostalCodeAndCity(postCode, town) {
  return `${postCode || ``} ${town || ``}`.trim()
}

export function formatPhone(phoneNumber) {
  if (phoneNumber) {
    const format = phoneNumber.startsWith("08") ? "$1 $2$3 $4 $5 $6" : "$1$2 $3 $4 $5 $6"

    return phoneNumber.replace(/^(\d)(\d)(\d{2})(\d{2})(\d{2})(\d{2})$/g, format)
  }

  return ``
}
