import { formatPrice } from "@corefront-shared/utils/format"

const PERCENT_MINI_TO_APPLY_VOUCHER = 1 - 0.75
const PRICE_MIN_TO_ACCEPT_ALL_PAYMENTS = 12
const PRICE_MIN_TO_ACCEPT_CARD = 1

export function getPrice(variant, invoiceRecurrence = 12, voucher = null) {
  if (!variant) {
    return {}
  }

  const priceRatio = invoiceRecurrence / 12
  const { standardPrice: sp, offerPrice: op } = variant
  const standardPrice = sp / priceRatio
  const offerPrice = (op || sp) / priceRatio
  let promoUnit = `€`
  let diff = standardPrice - offerPrice
  let promoValue = diff
  let price = Math.min(offerPrice, standardPrice)
  let betterPrice = diff > 0
  let voucherApplied = false

  if (voucher && voucher.minCartAmount < standardPrice) {
    let diffVoucher = 0
    let promoUnitVoucher = `€`

    if (voucher.type === `percent`) {
      diffVoucher = (standardPrice * voucher.amount) / 100
      promoUnitVoucher = `%`
    } else {
      diffVoucher = voucher.amount
    }

    const priceVoucher = standardPrice - diffVoucher
    if (priceVoucher >= PERCENT_MINI_TO_APPLY_VOUCHER * standardPrice && priceVoucher < price) {
      promoValue = voucher.amount
      promoUnit = promoUnitVoucher
      diff = diffVoucher
      price = standardPrice - diff
      betterPrice = diff > 0
      voucherApplied = true
    }
  }

  return {
    betterPrice,
    price,
    diff,
    standardPrice,
    offerPrice,
    promoValue,
    promoUnit,
    voucherApplied,
    dateToApply: voucherApplied ? voucher.endValidityDate : variant.campaignEndDate,
    annualPurchaseOnly: price < PRICE_MIN_TO_ACCEPT_ALL_PAYMENTS,
    disallowCardPurchase: price <= PRICE_MIN_TO_ACCEPT_CARD,
  }
}

const VARIANT_OTHER = 1
const VARIANT_EMPLOYEE_30_PERCENT = 2
const VARIANT_EMPLOYEE_1_EURO = 3

export function getProductVariantType(offerCode) {
  if (offerCode.startsWith(`H`)) {
    if (offerCode.endsWith(`C`)) {
      return VARIANT_EMPLOYEE_30_PERCENT
    }

    if (offerCode.endsWith(`C1`)) {
      return VARIANT_EMPLOYEE_1_EURO
    }
  }

  return VARIANT_OTHER
}

export function betterPriceSentence(
  t,
  offerPrice,
  standardPrice,
  diff,
  campaignEndDate,
  endPriceValidityDate,
  variantType,
  brandName,
  employeePriceFirstYear,
  formatDatetime,
) {
  if (variantType === VARIANT_EMPLOYEE_30_PERCENT || variantType === VARIANT_EMPLOYEE_1_EURO) {
    const lastSentence = t(`components:product.price_mention_employee`, { brandName })
    if (employeePriceFirstYear) {
      const amount = formatPrice(offerPrice)

      return (
        t(`components:product.price_mention_employee_first_year_amount`, {
          amount,
          date: campaignEndDate ? formatDatetime(campaignEndDate) : endPriceValidityDate,
          standardPrice: formatPrice(standardPrice),
        }) +
        ` ` +
        lastSentence +
        ` ` +
        t(`components:product.price_mention_employee_first_year_end`)
      )
    }

    if (variantType === VARIANT_EMPLOYEE_30_PERCENT) {
      const percent = formatPrice((diff * 100) / standardPrice)

      return t(`components:product.price_mention_employee_percent`, { percent }) + ` ` + lastSentence + `.`
    }

    const amount = formatPrice(offerPrice)

    return t(`components:product.price_mention_employee_amount`, { amount }) + ` ` + lastSentence + `.`
  }

  return t(`components:product.price_mention`, {
    price: formatPrice(offerPrice),
    date: campaignEndDate ? formatDatetime(campaignEndDate) : endPriceValidityDate,
    standardPrice: formatPrice(standardPrice),
    endPriceValidityDate,
  })
}

export function firstYearSentence(t, standardPrice, endPriceValidityDate, invoiceRecurrence) {
  return t(
    invoiceRecurrence === 12
      ? `components:product.price_mention_first_year`
      : `components:product.price_mention_first_2_years`,
    {
      standardPrice: formatPrice(standardPrice),
      endPriceValidityDate,
    },
  )
}

export const getTestStripeTokenFromUnderwriterId = underwriterId => {
  switch (underwriterId) {
    case `011`:
      return `pk_test_1OUIkjcsqVWZI4O1aJq65VTY`
    case `002`:
      return `pk_test_52VVj5FiM0QyZKymcmOseKOa`
    case `010`:
      return `pk_test_pStTJW3nERdTuAdMXNEP4NsQ`
    case `012`:
      return `pk_test_51HuI9hJ6jGqkghyW1TXKPYGvzl0WCGuj2BAv1wGlQbpoRt1LcfLreKtUjfpHuuP0N1k2iQmRPJLpBZpYieVLeLOk00CTrmjmfU`
    default:
      return ``
  }
}

export const getStripeTokenFromUnderwriterId = underwriterId => {
  switch (underwriterId) {
    case `011`:
      return `pk_live_kGDyEvMooP2pgPyx9FTG4Ibr`
    case `002`:
      return `pk_live_JRm94ZcrYNCphVeJ5U6ON9Bg`
    case `010`:
      return `pk_live_zQ6VkBCXhrfoiT6mQAaXjNOj`
    case `012`:
      return `pk_live_51HuI9hJ6jGqkghyW5EDGpsjt0Kkdd8LjFqIpLZNqZoVVD68P7brjDiUeUzwvuoEGDdBR9CYYw7twwnIV5e7euxGj00iVLrvfbP`
    default:
      return ``
  }
}
