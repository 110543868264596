import React, { useEffect, useState, useRef, useMemo } from "react"
import PropType from "prop-types"
import { ReCAPTCHA } from "react-google-recaptcha"
import { Error } from "../infoAndError.component"

export function useCaptcha(sitekey, thirdParty, lang, errorMessage) {
  const captchaRef = useRef()

  return useMemo(() => {
    return {
      name: `captcha`,
      Component: Captcha,
      props: { captchaRef, sitekey, thirdParty, lang, errorMessage },
      isReady: true,
      onSubmitFail: () => {
        if (captchaRef.current) {
          return captchaRef.current.reset()
        }
      },
      onSubmit: async values => {
        const token = await captchaRef.current.executeAsync()

        return {
          ...values,
          captchaToken: token,
        }
      },
    }
  }, [sitekey, thirdParty, lang, errorMessage])
}

export default function Captcha({ captchaRef, sitekey, thirdParty, lang, errorMessage }) {
  const { load, isLoaded } = thirdParty
  const [error, setError] = useState(false)

  useEffect(() => {
    load(`captcha`)
  }, [])

  return (
    isLoaded(`captcha`) && (
      <>
        <ReCAPTCHA
          hl={lang}
          ref={captchaRef}
          sitekey={sitekey}
          onErrored={() => setError(true)}
          grecaptcha={window.grecaptcha}
          size="invisible"
          id="captcha"
          badge="bottomleft"
        />
        {error && <Error>{errorMessage}</Error>}
      </>
    )
  )
}

Captcha.propTypes = {
  captchaRef: PropType.object.isRequired,
  sitekey: PropType.string.isRequired,
  thirdParty: PropType.shape({
    load: PropType.func.isRequired,
    isLoaded: PropType.func.isRequired,
  }),
  lang: PropType.string,
  errorMessage: PropType.node.isRequired,
}
