import React from "react"
import styled from "styled-components"
import { colors, fontSizes } from "@corefront-shared/styled/theme"
import propTypes from "prop-types"
import { formatPhone } from "@corefront-shared/utils/format"

const Cartouche = styled.div`
  display: flex;
  background-color: white;
  border: 1px solid ${colors(`grey.g800`)};
  width: ${props => (props.widthFixed ? `280px` : `180px`)};
  height: 35px;
  color: ${colors(`grey.g800`)};
  font-size: ${fontSizes(`large`)};
`

const Free = styled.div`
  width: 190px;
  margin-right: 8px;
  height: 34px;
  font-weight: 700;
  background-color: ${colors(`grey.g800`)};
  color: white;
  font-size: ${fontSizes(`x_small`)};
  line-height: ${fontSizes(`x_small`)};
  text-align: left;
`

const Arrow = styled.div`
  margin-top: 21px;
  height: 24px;
  width: 20px;
  background-color: white;
  transform: translateY(-50%) rotate(45deg);
`

const Anchor = styled.a`
  text-decoration: none;
  color: inherit;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: 8px;
`

const Text = styled.div`
  display: flex;
  margin-top: 5px;
  margin-left: 14px;
`

const Bold = styled.span`
  font-weight: ${props => props.fontWeight || 900};
`

export default function PhoneBox({ phoneNumber }) {
  return (
    <Cartouche widthFixed={phoneNumber.startsWith("08")}>
      <Wrapper>
        <Anchor href={`tel:${phoneNumber}`} data-testid="box_phone">
          <Bold>{formatPhone(phoneNumber)}</Bold>
        </Anchor>
      </Wrapper>
      {phoneNumber.startsWith("08") && (
        <>
          <Arrow />
          <Free>
            <Text>
              <Bold fontWeight={300}>service gratuit + coût de l'appel</Bold>
            </Text>
          </Free>
        </>
      )}
    </Cartouche>
  )
}

PhoneBox.propTypes = {
  phoneNumber: propTypes.string.isRequired,
}
