import React, { memo } from "react"
import styled from "styled-components"
import Footer from "../components/footer/footer.component"
import Header from "../components/header/header.component"
import Provider from "../providers/provider.component"
import Routes from "../routes/routes"
import GlobalStyles from "../layouts/globalStyles.component"
import HeadFonts from "@corefront-shared/styled/headFonts"
import { Helmet } from "react-helmet"
import Overlay from "../components/overlay/overlay.component"

const Spacer = styled.div`
  flex: 1;
`

const HTMLHeader = styled.header`
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
`

const dnsPrefetch = Object.values(APP_CONFIG.dnsPrefetch || {}).filter(x => !!x)

function Layout() {
  return (
    <Provider>
      <Helmet>
        {dnsPrefetch.map(dns => [
          <link rel="preconnect" href={dns} key={dns} />,
          <link rel="dns-prefetch" href={dns} key={dns} />,
        ])}
      </Helmet>
      <HeadFonts basePath={APP_CONFIG.baseUrl} />
      <GlobalStyles />
      <HTMLHeader>
        <Header />
      </HTMLHeader>
      <Routes />
      <Spacer />
      <Footer />
      <Overlay />
    </Provider>
  )
}

export default memo(Layout)
