import React from "react"
import { useAppContext } from "../../providers/app.provider"
import LayoutSidebar from "../../../layouts/layoutSidebar.component"
import Progress from "../../stepper/progress.component"
import Banner from "../../banner/banner.component"
import styled from "styled-components"
import { fontSizes } from "@corefront-shared/styled/theme"
import Notice from "../../notice/notice.component"
import FourStepSection from "./fourStepSection.component"
import { Button } from "@corefront-shared/components/button.component"
import Hand from "@corefront-shared/components/pictos/hand.component"
import breakpoint from "styled-components-breakpoint"
import { Trans, useTranslation } from "react-i18next"
import DevOnly from "@corefront-shared/components/devOnly.component"

const Introduction = styled.div``
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 48px;

  ${breakpoint(`medium`)`
    padding-bottom: 0px;`}
`
const NoticeWrapper = styled.div`
  padding: 24px 0px;
`
const ButtonStyled = styled(Button)`
  min-width: 235px;
`

const IntroductionTitle = styled.div`
  font-size: ${fontSizes("large")};
  padding-bottom: 16px;
`
const IntroductionContent = styled.div``

export default function StepOffer() {
  const { t } = useTranslation()
  const { setNextStep, state } = useAppContext()
  const { customer } = state.userData || {}
  const { loadingData } = state
  const identity = customer ? `${customer.firstName} ${customer.lastName}` : ``

  return (
    <>
      <Banner />
      <Progress />
      <DevOnly>
        <button onClick={setNextStep}>
          <div>next</div>
        </button>
      </DevOnly>
      <LayoutSidebar>
        <Introduction>
          <IntroductionTitle>
            <Trans t={t} i18nKey="common:tunnel.welcome" values={{ identity }} />
          </IntroductionTitle>
          <IntroductionContent>{t("common:tunnel.intro")}</IntroductionContent>
        </Introduction>
        <NoticeWrapper>
          <Notice icon={Hand}>{t("common:tunnel.notice")}</Notice>
        </NoticeWrapper>
        <ButtonWrapper>
          <ButtonStyled major onClick={setNextStep} disabled={loadingData} data-testid="next_step">
            Je continue
          </ButtonStyled>
        </ButtonWrapper>
      </LayoutSidebar>
      <FourStepSection />
    </>
  )
}
