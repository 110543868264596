import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { condition, fontSizes } from "@corefront-shared/styled/theme"

const Wrapper = styled.div`
  border: 1px dotted red;
  width: 100%;
  padding: 10px;
  ${condition(`center`)`
    margin: 0 auto;
    text-align: center;
  `}
`
const Label = styled.div`
  color: red;
  font-size: ${fontSizes(`xx_small`)};
  padding-bottom: 10px;
`

export default function DevOnly({ center = false, children }) {
  if (process.env.NODE_ENV === `production`) {
    return null
  }

  return (
    <Wrapper center>
      <Label>Only appear in dev mode</Label>
      {children}
    </Wrapper>
  )
}

DevOnly.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
