export function getPartnerWCBCode(policy) {
  const partner = policy?.partner
  switch (partner) {
    case "SU":
      return "HER_SAUR"
    case "KO":
      return "HER_KOMPAR"
    default:
      return "WEBCBDOMEO"
  }
}
