import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { colors, fontSizes } from "@corefront-shared/styled/theme"

const StyledNotice = styled.div`
  color: ${colors(`grey.g700`)};
  font-size: ${fontSizes(`small`)};
`

export default function Notice({ text }) {
  if (!text) {
    return null
  }

  return <StyledNotice data-testid={arguments[0][`data-testid`] || undefined}>{text}</StyledNotice>
}

Notice.propTypes = {
  text: PropTypes.string,
}
