import { useEffect, useState } from "react"

export function useLazyImage(path) {
  const [state, setState] = useState(() => ({
    src: ``,
    status: 0,
  }))

  useEffect(() => {
    if (path && state.status === 0) {
      setState({ src: ``, status: 1 })

      import(`../../assets/${path}`).then(
        image => {
          setState({ src: image.default, status: 2 })
        },
        () => {
          setState({ src: ``, status: 2 })
        },
      )
    }
  }, [path, state])

  return [state.src, state.status === 2]
}

export function useLazyLogo(partnerCode) {
  return useLazyImage(partnerCode ? `logos/LOGO_${partnerCode}@2X.png` : ``)
}
