import { fetchApiCore } from "@homeserve/api-fetcher"

const fetchCore = fetchApiCore({
  url: APP_CONFIG.apis.coreback.url,
  token: APP_CONFIG.apis.coreback.token,
})

export function createCaptchaHeader(captchaToken) {
  return {
    "x-captcha-token": captchaToken,
  }
}

export function postContactWcb(phone, partner, captchaToken) {
  return fetchCore(`/services/webcallback`, {
    method: `POST`,
    headers: createCaptchaHeader(captchaToken),
    body: JSON.stringify({
      partner,
      phone,
    }),
  })
}
