import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

const PictoWrapper = styled.div`
  display: none;
  ${breakpoint(`medium`)`
    margin-right: 16px;
    display: block;
  `}
`
const TextWrapper = styled.div``

const Bold = styled.span`
  font-weight: 900;
`

const Wrapper = styled.div`
  display: flex;
  padding: 24px;
  box-sizing: border-box;
  background-color: rgba(231, 52, 44, 0.04);
`

export default function Notice({ title, children, icon }) {
  const Icon = icon

  return (
    <Wrapper>
      {!!icon && (
        <PictoWrapper>
          <Icon width={32} height={32} />
        </PictoWrapper>
      )}
      <TextWrapper>
        {title && <Bold>{title}</Bold>}
        {children}
      </TextWrapper>
    </Wrapper>
  )
}

Notice.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  icon: PropTypes.any,
}
