import React from "react"
import PropTypes from "prop-types"
import Picto from "../picto.component"

export default function CircleCheck({
  size = 25,
  color = APP_CONFIG.theme.colors.valid,
  color2 = `white`,
  color3 = `none`,
  ...props
}) {
  return (
    <Picto viewBox="0 0 25 25" size={size} color={color} {...props}>
      <g>
        <circle cx="12.5" cy="12.5" r="12" stroke={color3} />
        <circle cx="12.5" cy="12.5" r="12" fill="none" />
      </g>
      <path
        fill={color2}
        d="M3.892 78L.2 74.306a.695.695 0 0 1 .982-.982l3.2 3.2L12.7 68.2a.695.695 0 0 1 .982.982L8.867 74l-3.992 4a.7.7 0 0 1-.983 0z"
        transform="translate(6.422 -60.504)"
      />
    </Picto>
  )
}

CircleCheck.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  color2: PropTypes.string,
  color3: PropTypes.string,
}
