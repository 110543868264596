import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const Flag = styled.span`
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  width: inherit;

  :before,
  :after {
    content: "";
    position: absolute;
    top: 0;
    width: 33.3%;
    height: 100%;
  }

  :before {
    /* stylelint-disable-next-line color-no-hex */
    background-color: #3150a3;
    left: 0;
  }

  :after {
    /* stylelint-disable-next-line color-no-hex */
    background-color: #ed2224;
    right: 0;
  }
`

const Wrapper = styled.div`
  display: inline-flex;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-color: white;
  border-radius: 50%;
  padding: 2px;
`

export default function FrenchFlag({ size = 24 }) {
  return (
    <Wrapper size={size}>
      <Flag />
    </Wrapper>
  )
}

FrenchFlag.propTypes = {
  size: PropTypes.number,
}
