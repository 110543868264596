import PropTypes from "prop-types"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { colors } from "@corefront-shared/styled/theme"

const Label = styled.label`
  display: ${props => props.display || `inline-block`};
  margin-bottom: 8px;
  font-weight: 900;
  color: ${colors(`grey.g800`)};

  ${breakpoint(`medium`)`
    margin-top: 10px;
  `}
`

export default Label

Label.propTypes = {
  children: PropTypes.node.isRequired,
}
