import React from "react"
import Picto from "../picto.component"
import PropTypes from "prop-types"

export default function Smartphone({ size = 45, ...props }) {
  return (
    <Picto
      width={size}
      height={size}
      viewBox="0 0 25 25"
      transform="rotate(99) translate(10 2)"
      style={{ fillRule: "evenodd" }}
      d="M2.4 0h9.392a2.4 2.4 0 0 1 2.4 2.4v19.066a2.413 2.413 0 0 1-2.4 2.444H2.4A2.445 2.445 0 0 1 0 21.466V2.4A2.435 2.435 0 0 1 2.4 0zm2.919 2.012a.309.309 0 0 1-.287-.287.268.268 0 0 1 .287-.24h3.545c.192 0 .287.1.287.24a.276.276 0 0 1-.287.287zm1.773 19.55a.594.594 0 0 1 .575.623.575.575 0 0 1-1.15 0 .594.594 0 0 1 .575-.623zM1.2 3.45h11.785v17.01H1.2z"
      {...props}
    />
  )
}

Smartphone.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}
