import React from "react"
import Picto from "../picto.component"

export default function Phone(props) {
  return (
    <Picto
      viewBox="0 0 1408 1408"
      d="M3,1091q6-18,76-53,19-11,54-30t63.5-35Q225,957,250,942q4-3,25-17.5T310.5,903q14.5-7,28.5-7,20,0,50,28.5a487.45,487.45,0,0,1,55,62,602.23,602.23,0,0,0,53,62q28,28.5,46,28.5,9,0,22.5-5a218.3,218.3,0,0,0,20.5-8.5q7-3.5,24-14t19-11.5q137-76,235-174t174-235q1-2,11.5-19t14-24a218.3,218.3,0,0,0,8.5-20.5q5-13.5,5-22.5,0-18-28.5-46a602.23,602.23,0,0,0-62-53,487.46,487.46,0,0,1-62-55Q896,359,896,339q0-14,7-28.5T924.5,275Q939,254,942,250q15-25,31-53.5t35-63.5q19-35,30-54,35-70,53-76,7-3,21-3,27,0,70.5,10T1251,31q50,21,106,122,51,94,51,186a385.76,385.76,0,0,1-3.5,52.5Q1401,417,1392,449t-14.5,47.5Q1372,512,1357,552t-18,49q-35,98-83,175-79,128-215.5,264.5T776,1256q-77,48-175,83-9,3-49,18t-55.5,20.5Q481,1383,449,1392t-57.5,12.5A385.75,385.75,0,0,1,339,1408q-92,0-186-51Q52,1301,31,1251q-11-25-21-68.5T0,1112Q0,1098,3,1091Z"
      {...props}
    />
  )
}
