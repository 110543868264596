import React, { Suspense, useEffect } from "react"
import { Route, Switch, useLocation } from "react-router-dom"
import Page404 from "../pages/404"
import StepOffer from "../components/tunnel/stepOffer/stepOffer.component"

export const routes = {
  home: {
    path: "/",
    component: StepOffer,
  },
  policyStarted: {
    path: "/contrat-demarre",
    component: React.lazy(() => import("../pages/policyStarted")),
  },
  dataError: {
    path: "/erreur",
    component: React.lazy(() => import("../pages/dataError")),
  },
  stepResume: {
    path: "/vos-informations",
    component: React.lazy(() => import("../components/tunnel/stepResume/stepResume.component")),
  },
  stepPayment: {
    path: "/paiement",
    component: React.lazy(() => import("../components/tunnel/stepPayment/stepPayment.component")),
  },
  stepSignature: {
    path: "/signature",
    component: React.lazy(() => import("../components/tunnel/stepSignature/stepSignature.component")),
  },
  stepSuccess: {
    path: "/success",
    component: React.lazy(() => import("../pages/successPage")),
  },
  404: {
    path: "/404",
    component: Page404,
  },
}

/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
const Routes = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Suspense fallback={null}>
      <Switch>
        {Object.keys(routes).map(routeName => (
          <Route key={routeName} exact {...routes[routeName]} />
        ))}
      </Switch>
    </Suspense>
  )
}

export default Routes
