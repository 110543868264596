import React from "react"
import styled from "styled-components"
import WebCallback from "../webCallback/webCallback.component"
import { useAppContext } from "../providers/app.provider"

const Wrapper = styled.div`
  position: fixed;
  right: 10px;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export default function Overlay() {
  const {
    state: { currentStep },
  } = useAppContext()

  return <Wrapper>{currentStep >= 0 && <WebCallback />}</Wrapper>
}
