import React, { useEffect } from "react"
import styled from "styled-components"
import Headset from "@corefront-shared/components/pictos/headset.component"
import Proptypes from "prop-types"
import { Button } from "../button.component"
import { colors, fontSizes } from "@corefront-shared/styled/theme"

const MediumTitle = styled.h3`
  text-align: center;
  width: 100%;
  word-break: break-word;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 0;
  color: ${colors(`primary`)};
  font-size: ${fontSizes(`x_large`)};
`

const Bold = styled.span`
  font-weight: ${props => props.fontWeight || 900};
`

const TextModal = styled.div`
  max-width: 500px;
  margin: 13px auto 20px;
  color: ${props => props.color || props.theme.colors.grey.g800};
`

const Utility = styled.div`
  max-width: 500px;
  margin: 22px auto;
  color: ${props => props.color || props.theme.colors.grey.g600};
  font-weight: 300;
  line-height: ${props => props.theme.fonts.sizes.small};
  font-size: ${props => props.theme.fonts.sizes.small};
`

const HeadsetContainer = styled.div`
  box-shadow: ${props => props.theme.shadows.callback};
  background-color: ${props => props.backgroundColor};
  position: absolute;
  width: 45px;
  height: 45px;
  margin: auto auto auto 52%;
  border-radius: 50%;
  z-index: 20;
  top: 0px;
  & > svg {
    margin: 7px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const FormContainer = styled.div`
  text-align: center;
  position: relative;
  padding-bottom: 60px;

  .grecaptcha-badge {
    position: absolute !important;
    transform: translateX(-20px);
  }
`

const TextCenter = styled.div`
  text-align: center;
`

const Message = styled.div`
  margin-bottom: 50px;
`

const StyledButton = styled(Button).attrs(props => ({
  textColor: props.headsetColor,
  backgroundColor: props.backgroundColor,
}))`
  display: inline-flex;
  margin: 0 auto;
`

export default function WebCallbackModal({
  t,
  onClose,
  schedules,
  headsetColor,
  backgroundColor,
  addEventTracking,
  image,
  hasError,
  children,
}) {
  useEffect(() => {
    addEventTracking(`eventGA`, {
      eventCategory: `WebcallBack`,
      eventAction: `initié`,
      eventLabel: window.location.pathname,
    })
  }, [])

  return (
    <Container data-testid="webcallback_modal">
      <TextCenter>
        {image}
        <HeadsetContainer backgroundColor={backgroundColor}>
          <Headset size={32} color={headsetColor} />
        </HeadsetContainer>
      </TextCenter>
      <TextCenter>
        <MediumTitle seo="h3" marginBottom={false}>
          <Bold fontWeight={700}>{t(`components:webcallback.title`)}</Bold>
        </MediumTitle>
      </TextCenter>
      {!hasError ? (
        <TextCenter data-testid="webcallback_modal_success">
          <Message>{t(`components:webcallback.success`)}</Message>
          <StyledButton onClick={onClose} data-testid="webcallback_modal_button_close">
            {t(`components:generic.close_button`)}
          </StyledButton>
        </TextCenter>
      ) : (
        <FormContainer>
          <TextModal>
            {t(`components:webcallback.form_title`, { town: `Lyon` })}, <br />
            <Bold data-testid="webcallback_schedules">{schedules}</Bold>.
          </TextModal>
          {children}
          <Utility>* {t(`components:webcallback.form_phone_mention`)}</Utility>
        </FormContainer>
      )}
    </Container>
  )
}

WebCallbackModal.propTypes = {
  t: Proptypes.func.isRequired,
  onClose: Proptypes.func.isRequired,
  children: Proptypes.node.isRequired,
  schedules: Proptypes.string.isRequired,
  headsetColor: Proptypes.string.isRequired,
  backgroundColor: Proptypes.string.isRequired,
  image: Proptypes.node.isRequired,
  addEventTracking: Proptypes.func.isRequired,
  hasError: Proptypes.bool.isRequired,
}
