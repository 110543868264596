import React from "react"
import Picto from "@corefront-shared/components/picto.component"
import PropTypes from "prop-types"

export default function Oups({ color = "red", ...props }) {
  return (
    <Picto viewBox="0 0 102.145 83.993" color={color} {...props}>
      <g>
        <path d="M93.069 6H9.076A8.1 8.1 0 0 0 1 14.076v67.84a8.1 8.1 0 0 0 8.076 8.076h83.993a8.1 8.1 0 0 0 8.076-8.076v-67.84A8 8 0 0 0 93.069 6zM9.076 9.23h83.993a4.86 4.86 0 0 1 4.846 4.846v8.076H4.23v-8.076A4.759 4.759 0 0 1 9.076 9.23zm83.993 77.532H9.076a4.86 4.86 0 0 1-4.846-4.845V25.383h93.685v56.534a4.759 4.759 0 0 1-4.846 4.845z" />
        <path
          d="M44.846 18.691a4.759 4.759 0 0 0 4.846-4.846 4.846 4.846 0 0 0-9.691 0 4.759 4.759 0 0 0 4.845 4.846zm0-6.461a1.526 1.526 0 0 1 1.615 1.615 1.615 1.615 0 0 1-3.23 0 1.526 1.526 0 0 1 1.615-1.615z"
          transform="translate(23.995 1.846)"
        />
        <path
          d="M51.846 18.691a4.759 4.759 0 0 0 4.846-4.846 4.846 4.846 0 0 0-9.691 0 4.759 4.759 0 0 0 4.845 4.846zm0-6.461a1.526 1.526 0 0 1 1.615 1.615 1.615 1.615 0 0 1-3.23 0 1.526 1.526 0 0 1 1.615-1.615z"
          transform="translate(28.301 1.846)"
        />
        <path
          d="M58.846 18.691a4.759 4.759 0 0 0 4.846-4.846 4.846 4.846 0 0 0-9.691 0 4.759 4.759 0 0 0 4.845 4.846zm0-6.461a1.526 1.526 0 0 1 1.615 1.615 1.615 1.615 0 1 1-3.23 0 1.526 1.526 0 0 1 1.615-1.615z"
          transform="translate(32.608 1.846)"
        />
        <path
          d="M28.461 38.922A6.461 6.461 0 1 0 22 32.461a6.48 6.48 0 0 0 6.461 6.461zm0-9.691a3.23 3.23 0 1 1-3.23 3.23 3.24 3.24 0 0 1 3.23-3.231z"
          transform="translate(12.92 12.305)"
        />
        <path
          d="M40.461 38.922A6.461 6.461 0 1 0 34 32.461a6.48 6.48 0 0 0 6.461 6.461zm0-9.691a3.23 3.23 0 1 1-3.23 3.23 3.24 3.24 0 0 1 3.23-3.231z"
          transform="translate(20.303 12.305)"
        />
        <path transform="translate(0 6.305)" stroke={color} strokeWidth="3" d="m34.073 65.5 34-8" />
      </g>
    </Picto>
  )
}

Oups.propTypes = {
  color: PropTypes.string,
}
