import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { colors, fontSizes } from "@corefront-shared/styled/theme"

const StepText = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 0;
  white-space: nowrap;
  margin-top: 8px;
`

const StepRound = styled.div`
  text-align: center;
  width: 36px;
  height: 36px;
  padding: 8px;
  border-radius: 50%;
  border: solid 2px;
`

const Step = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: ${props => (props.passed ? "pointer" : "auto")};

  ${StepRound} {
    color: ${props => (props.selected ? "white" : props.passed ? colors(`primary`) : "grey")};
    border-color: ${props => (props.selected || props.passed ? colors(`primary`) : "grey")};
    background-color: ${props => (props.selected ? colors(`primary`) : "white")};
  }
  ${StepText} {
    color: ${props => (props.passed || props.selected ? colors(`primary`) : "grey")};
    font-weight: ${props => (props.selected ? 800 : 500)};
    font-size: ${fontSizes("small")};
  }
`

const ProgressStep = ({ step, isCurrent, isAvailable, setStep, title }) => {
  return (
    <Step
      data-testid={`progress_step_${step}${isCurrent ? "_selected" : ""}`}
      passed={isAvailable}
      selected={isCurrent}
      onClick={setStep}
    >
      <StepRound>{step}</StepRound>
      <StepText>{title}</StepText>
    </Step>
  )
}

export default ProgressStep

ProgressStep.propTypes = {
  step: PropTypes.number.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  setStep: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}
