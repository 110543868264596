import {
  franceValidationConfig,
  createIbanValidator,
  createPhoneValidator,
  createPlaceholder,
  createMask,
} from "@corefront-shared/utils/validate"

const config = franceValidationConfig()

export const iban = createIbanValidator(config.iban)
export const ibanMask = createMask(config.iban)
export const ibanPlaceholder = createPlaceholder(config.iban)
export const tel = createPhoneValidator(config.tel)
export const telMask = createMask(config.tel)

export {
  RESIDENCE_MAXLENGTH,
  required,
  enumeration,
  mini,
  noNumber,
  text,
  ensura,
  email,
  noopMask,
  cedilleMask,
  agentCommandId,
  validateAgentId,
  compose,
} from "@corefront-shared/utils/validate"
