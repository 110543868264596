import React from "react"
import PropTypes from "prop-types"
import Picto from "../picto.component"

export default function Hand({ color, ...props }) {
  return (
    <Picto viewBox="0 0 34.324 31.959" color={color} {...props}>
      <path
        fill="#fff"
        d="M3.052 17.153a3.025 3.025 0 00.89 2.151.7.7 0 00.484.2.683.683 0 00.484-1.167 1.678 1.678 0 010-2.365.685.685 0 00-.968-.968 3.025 3.025 0 00-.89 2.149z"
        transform="rotate(-21 17.6 5.299)"
      />
      <path d="M16.287 11.114l2.368-2.368-.034.03z" transform="rotate(-21 17.6 5.299)" />
      <path
        d="M2.459 11.163c.128-.15.263-.293.4-.428l4.269-4.267a2.477 2.477 0 012.95-.417l3.228-3.232a2.687 2.687 0 013.787 0 2.627 2.627 0 01.687 1.182 2.676 2.676 0 013.618 3.942l-.154.154a2.68 2.68 0 011.167 4.47l-.465.465a2.6 2.6 0 011.167.683 2.684 2.684 0 010 3.783l-6.756 6.756a9.654 9.654 0 01-6.8 2.845h-.059a9.447 9.447 0 01-6.722-2.778 9.586 9.586 0 01-.317-13.158zm1.3 12.18a8.044 8.044 0 005.8 2.376 8.257 8.257 0 005.839-2.44l6.759-6.751a1.328 1.328 0 000-1.85 1.351 1.351 0 00-1.85 0l-4.726 4.718a.683.683 0 01-.965-.965l4.707-4.711 2.117-2.117a1.31 1.31 0 000-1.85 1.344 1.344 0 00-1.85 0l-5.8 5.8a.683.683 0 01-.965-.968l7.6-7.6a1.317 1.317 0 000-1.85 1.351 1.351 0 00-1.854 0l-1.5 1.5-1.723 1.717-3.378 3.376a.685.685 0 11-.968-.968l3.378-3.378 1.745-1.745a1.31 1.31 0 000-1.85 1.344 1.344 0 00-1.85 0l-3.228 3.232a2.481 2.481 0 01-.417 2.95l-1.772 1.772a3.829 3.829 0 01-.083 4.819.255.255 0 01-.045.045.676.676 0 01-.92.045.683.683 0 01-.086-.965 2.466 2.466 0 00-.158-3.341.657.657 0 00-.075-.071.687.687 0 01-.071-1.032l2.252-2.252a1.126 1.126 0 00-1.568-1.561l-4.256 4.275a4.176 4.176 0 00-.338.375 8.216 8.216 0 00.251 11.268z"
        transform="rotate(-21 17.6 5.299)"
      />
      <path
        d="M3.052 17.153a3.025 3.025 0 00.89 2.151.7.7 0 00.484.2.683.683 0 00.484-1.167 1.678 1.678 0 010-2.365.685.685 0 00-.968-.968 3.025 3.025 0 00-.89 2.149zM5.841 6.065a.683.683 0 00.683-.683 1.674 1.674 0 011.67-1.67.685.685 0 000-1.37 3.044 3.044 0 00-3.04 3.04.683.683 0 00.687.683z"
        transform="rotate(-21 17.6 5.299)"
      />
      <path
        d="M3.499 5.92a.683.683 0 00.683-.683 3.874 3.874 0 013.874-3.87.685.685 0 000-1.37 5.255 5.255 0 00-5.255 5.255.683.683 0 00.698.668zM22.521 22.746a3.044 3.044 0 003.04-3.04.685.685 0 10-1.37 0 1.674 1.674 0 01-1.67 1.67.685.685 0 000 1.37z"
        transform="rotate(-21 17.6 5.299)"
      />
      <path
        d="M22.664 25.088a5.255 5.255 0 005.255-5.255.685.685 0 10-1.37 0 3.874 3.874 0 01-3.885 3.885.685.685 0 000 1.37z"
        transform="rotate(-21 17.6 5.299)"
      />
    </Picto>
  )
}

Hand.propTypes = {
  color: PropTypes.string,
}
