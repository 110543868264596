import React from "react"
import styled from "styled-components"
import { fontSizes, useThemeContext } from "@corefront-shared/styled/theme"
import Section from "@corefront-shared/components/section.component"
import escda from "../../assets/image/escda.png"

const Wrapper = styled.div`
  display: flex;
  height: 53px;
`
const EscdaText = styled.div`
  font-size: ${fontSizes("small")};
  margin-left: 8px;
  align-self: center;
`

export default function FooterEscdaSection() {
  const { colors: themeColors } = useThemeContext()

  return (
    <Section bgColor={themeColors.footer} bgWidth="full" padding>
      <Wrapper>
        <img src={escda} />
        <EscdaText>Catégorie Services à l'habitat - Étude BVA - Viséo CI - Plus d'infos sur escda.fr.</EscdaText>
      </Wrapper>
    </Section>
  )
}
