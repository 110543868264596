import { useCaptcha as useCaptchaShared } from "@corefront-shared/components/form/plugins/captcha.component"
import { useThirdPartiesContext } from "@corefront-shared/providers/thridParties/thirdParties.provider"

export function useCaptcha(wcb = false) {
  const thirdParties = useThirdPartiesContext()

  const token = wcb ? APP_CONFIG.apis.coreback.captchaToken : APP_CONFIG.apis.payment.captchaToken

  return useCaptchaShared(
    token,
    thirdParties,
    "fr",
    "Une erreur est survenue lors de la validation du captcha. Veuillez reessayer ultérieurement",
  )
}
