function formatNumber2digits(number) {
  return number >= 10 ? number : `0${number}`
}

export function formatDatetime(CustomDate, datetime, format) {
  const date = datetime ? new CustomDate(datetime) : new CustomDate()
  let output = format.toLowerCase()

  if (output.includes(`dd`)) {
    const day = date.getDate()
    output = output.replace(`dd`, formatNumber2digits(day))
  }

  if (output.includes(`mm`)) {
    const month = date.getMonth() + 1
    output = output.replace(`mm`, formatNumber2digits(month))
  }

  if (output.includes(`yyyy`)) {
    output = output.replace(`yyyy`, date.getFullYear())
  }

  if (output.includes(`ii`)) {
    const minutes = date.getMinutes()
    output = output.replace(`ii`, formatNumber2digits(minutes))
  }

  if (output.includes(`hh`)) {
    const hours = date.getHours()
    output = output.replace(`hh`, formatNumber2digits(hours))
  }

  if (output.includes(`ss`)) {
    const seconds = date.getSeconds()
    output = output.replace(`ss`, formatNumber2digits(seconds))
  }

  return output
}

export function getDateOrMockDate() {
  return process.env.ENVIRONMENT === `mock` && typeof window !== `undefined` ? window.MockDate : Date
}
