import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Section from "@corefront-shared/components/section.component"
import { percent } from "@corefront-shared/styled/theme"
import ProductBlock from "../components/product/productBlock.component"

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const SidebarWrapper = styled.div`
  > section {
    display: flex;
    justify-content: center;
  }
  width: 100%;
  margin-bottom: 16px;
  order: -1;

  ${breakpoint(`medium`)`
    width: ${percent(4 / 12)};
    margin: 0;
    order: 1;
  `}
`

const Content = styled.div`
  > section {
    display: flex;
    justify-content: center;
  }
  width: 100%;
  order: 0;

  ${breakpoint(`medium`)`
    width: ${percent(15 / 24)};
  `}
`

export default function LayoutSidebar({ children }) {
  return (
    <Section padding>
      <Wrapper>
        <Content>{children}</Content>
        <SidebarWrapper>
          <div>
            <ProductBlock />
          </div>
        </SidebarWrapper>
      </Wrapper>
    </Section>
  )
}

LayoutSidebar.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
}
