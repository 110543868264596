import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Phone from "@corefront-shared/components/pictos/phone.component"
import { fontSizes, colors } from "@corefront-shared/styled/theme"
import breakpoint from "styled-components-breakpoint"
import { formatPhone } from "@corefront-shared/utils/format"

const BlockPhone = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex-shrink: 0;
  ${breakpoint(`medium`)`
    display: flex;
  `}
`

const Number = styled.div`
  color: ${colors(`primary`)};
  font-size: ${fontSizes(`large`)};
  font-weight: 900;
  display: flex;
  align-items: center;
`

const PictoContainer = styled.span`
  width: 32px;
  height: 32px;
  background: ${colors(`primary`)};
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transform-origin: center center;
  transform: rotate(90deg);
  margin-right: 8px;
`

const Notice = styled.p`
  font-size: ${fontSizes(`x_small`)};
  color: ${colors(`grey.g700`)};
  margin: 4px 0 0 0;
`

export default function HeaderPhone({ phoneNumber = `` }) {
  if (!phoneNumber) {
    return null
  }

  return (
    <BlockPhone>
      <Number data-testid="cobrand_header_phone">
        <PictoContainer>
          <Phone color="white" size={16} />
        </PictoContainer>
        {formatPhone(phoneNumber)}
      </Number>
      <Notice>Service gratuit + coût de l'appel</Notice>
    </BlockPhone>
  )
}

HeaderPhone.propTypes = {
  phoneNumber: PropTypes.string,
}
