import React from "react"
import { colors } from "@corefront-shared/styled/theme"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

const Separator = styled.div`
  width: auto;
  border-top: 8px solid ${colors("primary")};
  display: none;
  ${breakpoint(`medium`)`
    display: flex;
  `}
`

export default function BannerSeparator() {
  return <Separator />
}
