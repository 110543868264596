const { FetchApiError } = require("@homeserve/api-fetcher")

export function sentryInit(dsn, environment, release) {
  const Sentry = (window.Sentry = require(`@sentry/browser`))

  Sentry.init({
    dsn,
    release,
    environment,
  })
}

export function sentryCaptureException(error, data = null) {
  if (!window.Sentry) {
    return
  }

  let exception = ``
  const scopeExtra = { ...data }

  if (error instanceof FetchApiError) {
    exception = error
    scopeExtra.request = error.request
    scopeExtra.response = error.response
  } else if (error instanceof Error) {
    exception = error
  } else {
    const errorMessage = String(error || `No error message`)
    exception = new Error(errorMessage)
  }

  window.Sentry.withScope(scope => {
    for (const extra in scopeExtra) {
      scope.setExtra(extra, scopeExtra[extra])
    }

    window.Sentry.captureException(exception)
  })
}
