import { DidomiSDK } from "@didomi/react"
import React, { useState } from "react"
import PropTypes from "prop-types"

export default function Didomi({ apiKey, sdkPath, onReady, children }) {
  const [didomiObject, setDidomiObject] = useState(null)

  return (
    <>
      <DidomiSDK
        apiKey={apiKey}
        sdkPath={sdkPath}
        iabVersion={2}
        gdprAppliesGlobally
        onReady={didomi => {
          onReady()
          setDidomiObject(didomi)
        }}
      />
      {children({ clickHandler: didomiObject && didomiObject.preferences.show })}
    </>
  )
}

Didomi.propTypes = {
  apiKey: PropTypes.string.isRequired,
  sdkPath: PropTypes.string.isRequired,
  onReady: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
}
