import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { condition } from "@corefront-shared/styled/theme"
import breakpoint from "styled-components-breakpoint"
import { flexCenter as sharedFlexCenter } from "@corefront-shared/styled/styles"
import noop from "@corefront-shared/utils/noop"

const Tooltip = styled.span`
  display: none;
  color: white;
  margin-left: 20px;
`

const SIZE_DESKTOP = 56
const SIZE_MOBILE = 40

const Wrapper = styled.div`
  display: ${condition(`hidden`, `none`, `flex`)};
  justify-content: space-between;
  position: relative;
  background-color: ${props => props.backgroundColor};
  border-radius: 50%;
  margin-bottom: 10px;

  ${breakpoint(`small`)`
    :hover {
      border-radius: ${SIZE_DESKTOP}px;

      ${Tooltip} {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  `}
`

const PictoWrapper = styled.div`
  ${sharedFlexCenter};
  position: relative;
  height: ${SIZE_MOBILE}px;
  width: ${SIZE_MOBILE}px;

  ${breakpoint(`small`)`
    height: ${SIZE_DESKTOP}px;
    width: ${SIZE_DESKTOP}px;

    :hover {
      border-radius: ${SIZE_DESKTOP}px;

      ${Tooltip} {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  `}

  > svg {
    flex: 1;
  }

  :after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: ${props => props.alertColor};
  }
`

function OverlayPicto({ onClick, tooltip, children, backgroundColor, alertColor, innerRef = noop, hidden = false }) {
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      hidden={hidden}
      onClick={onClick}
      ref={innerRef}
      data-testid={arguments[0][`data-testid`] || undefined}
    >
      <Tooltip>{tooltip}</Tooltip>
      <PictoWrapper alertColor={alertColor}>{children}</PictoWrapper>
    </Wrapper>
  )
}

export default React.forwardRef((props, ref) => <OverlayPicto {...props} innerRef={ref} />)

OverlayPicto.propTypes = {
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  alertColor: PropTypes.string.isRequired,
  innerRef: PropTypes.object,
  hidden: PropTypes.bool,
}
