import React from "react"
import Section from "@corefront-shared/components/section.component"
import { fontSizes, useThemeContext } from "@corefront-shared/styled/theme"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import bannerImg from "../../assets/image/hermes-visu.png"

const BannerText = styled.div`
  font-size: ${fontSizes("xx_large")};
  font-weight: 900;
  color: white;
  line-height: 1.5;
  margin-bottom: 16px;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0px;
  ${breakpoint(`medium`)`
  padding: 8px 0px;
`}
`

const ContentRight = styled.div`
  display: none;
  margin-left: 15px;
  flex: 1;

  ${breakpoint(`medium`)`
  display: flex;
  justify-content: flex-end;
`}
`

const ImageOutline = styled.div`
  padding-bottom: 16px;
  * {
    width: 213px;
  }
`

const ContentLeft = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0;
  }

  ${breakpoint(`medium`)`
    display: none
    color: white;
    align-items: normal;
    text-align: left;
  `}

  > :not(:last-child) {
    margin-bottom: 24px;
  }
`

export default function Banner() {
  const { colors } = useThemeContext()

  return (
    <Section bgWidth="full" bgColor={colors.primary} bevel bevelDesktop data-testid="big_banner">
      <Content>
        <ContentLeft>
          <BannerText>Plus que quelques clics pour profiter de votre assistance HomeServe</BannerText>
        </ContentLeft>
        <ContentRight>
          <ContentRight>
            <ImageOutline>
              <img src={bannerImg} />
            </ImageOutline>
          </ContentRight>
        </ContentRight>
      </Content>
    </Section>
  )
}
