import React from "react"
import Headset from "@corefront-shared/components/pictos/headset.component"
import OverlayPicto from "@corefront-shared/components/webCallback/overlayPicto.component"
import { ModalButton } from "@homeserve/react-modal"
import styled from "styled-components"
import WebCallbackModalStatic from "./webCallbackModal.component"
import { useThemeContext } from "@corefront-shared/styled/theme"
import { useAppContext } from "../providers/app.provider"
import { getPartnerWCBCode } from "./getPartner"
import { Empty } from "@corefront-shared/components/empty.component"

const Bold = styled.span`
  font-weight: 900;
`

export default function WebCallback() {
  const { colors } = useThemeContext()
  const {
    state: { userData },
  } = useAppContext()
  const policy = userData?.policy

  if (!policy) {
    return <Empty />
  }

  return (
    <ModalButton
      modalComponent={WebCallbackModalStatic}
      modalProps={{
        partnerCode: getPartnerWCBCode(policy),
        schedules: "du lundi au vendredi de 9h à 18h et le samedi de 9h à 12h",
      }}
    >
      {(onClick, ref) => (
        <OverlayPicto
          backgroundColor={colors.helper}
          alertColor={colors.valid}
          data-testid="webcallback_container"
          onClick={onClick}
          ref={ref}
          tooltip={
            <>
              <Bold>Une question ?&nbsp;</Bold> Nous vous rappelons&nbsp;<Bold>gratuitement</Bold>
            </>
          }
        >
          <Headset size={35} color="#FFF" />
        </OverlayPicto>
      )}
    </ModalButton>
  )
}
