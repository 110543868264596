// eslint-disable-next-line
const emailRegex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i

const digitOrCharRegex = /^[A-Z\d]$/
const digitRegex = /^\d$/

export const franceValidationConfig = () => ({
  iban: {
    prefix: `FR`,
    max: 27,
    placeHolder: `FRXX XXXX XXXX XXXX XXXX XXXXXXX`,
    mask: {
      mask: [
        `F`,
        `R`,
        digitRegex,
        digitRegex,
        ` `,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        ` `,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        ` `,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        ` `,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        ` `,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
      ],
      unmask: value => value.replace(/ /g, ``),
    },
  },
  tel: {
    lengths: [10],
    regex: /^0[12345679]\d{8}$/,
    mask: {
      mask: [/\d/, /\d/, ` `, /\d/, /\d/, ` `, /\d/, /\d/, ` `, /\d/, /\d/, ` `, /\d/, /\d/],
      unmask: value => value.replace(/[^\d]/g, ``),
    },
  },
  postalCode: {
    format: /^\d{5}$/,
    // corse and dom tom
    exclude: [/20\d{3}/, /9[6789]{1}\d{3}/],
    excludeFromDepartments: blacklistedDepartments =>
      blacklistedDepartments
        .map(dep => dep.code)
        .filter(dep => dep !== `2a` && dep !== `2b` && dep.length < 3)
        .map(dep => new RegExp(`${dep}\\d{3}`)),
  },
  customerId: {
    prefix: `C`,
    max: 9,
    regex: /^C([0-9]{8,})$/,
    mask: {
      mask: [`C`, digitRegex, digitRegex, digitRegex, digitRegex, digitRegex, digitRegex, digitRegex, digitRegex],
      unmask: value => value.replace(/ /g, ``),
    },
  },
})

export const belgiumValidationConfig = () => ({
  iban: {
    prefix: `BE`,
    max: 16,
    placeHolder: `BEXX XXXX XXXX XXXX`,
    mask: {
      mask: [
        `B`,
        `E`,
        digitRegex,
        digitRegex,
        ` `,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        ` `,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        ` `,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
        digitOrCharRegex,
      ],
      unmask: value => value.replace(/ /g, ``),
    },
  },
  tel: {
    lengths: [9, 10],
    regex: /^0\d{8,9}$/,
    mask: noopMask,
  },
  postalCode: {
    format: /^\d{4}$/,
    exclude: [],
    excludeFromDepartments: () => [],
  },
  customerId: {
    mask: noopMask,
  },
})

export const RESIDENCE_MAXLENGTH = 27

export function createPhoneValidator(config) {
  return function phone(value) {
    if (!value) return `utils:validate.form_error_required`

    const { lengths, regex } = config
    const length = value.length

    if (!lengths.includes(length)) {
      return [`utils:validate.form_error_phone_length`, { nbNumber: lengths.join(`/`) }]
    }

    return regex.test(value) ? null : `utils:validate.form_error_phone_invalid`
  }
}
export function required(value) {
  if (value === false) return null
  if (!value) return `utils:validate.form_error_required`

  return null
}

export function enumeration(values) {
  return value => {
    if (!values.includes(value)) {
      return `utils:validate.form_error_enumeration`
    }

    return null
  }
}

export function mini(nb) {
  return value => {
    if ((value.match(/\w/g) || []).length < nb) {
      return [`utils:validate.form_error_nb_char`, { count: nb }]
    }

    return null
  }
}

export function noNumber(value) {
  return /\d/g.test(value) ? `utils:validate.form_error_no_number` : null
}

export function text(value) {
  if (!/^[0-9a-zA-Z&-.’âêîôûéàèùäëïöüÂÊÎÔÛÉÀÈÙÄËÏÖÜÇç ]+$/.test(value)) {
    return `utils:validate.form_error_invalid_char`
  }

  return null
}

export function ensura(value) {
  if (!/^[0-9a-zA-Z&-.’âêîôûéàèùäëïöüÂÊÎÔÛÉÀÈÙÄËÏÖÜ ]+$/.test(value)) {
    return `utils:validate.form_error_invalid_char`
  }

  return null
}

function mod97(string) {
  let checksum = string.slice(0, 2)
  for (let offset = 2; offset < string.length; offset += 7) {
    const fragment = String(checksum) + string.substring(offset, offset + 7)
    checksum = parseInt(fragment, 10) % 97
  }

  return checksum
}

export function createIbanValidator(config) {
  return function iban(value) {
    const error = `utils:validate.form_error_iban`

    if (/[ \t]/.test(value)) {
      return error
    }
    const cleanIban = String(value)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ``) // keep only alphanumeric characters

    const code = cleanIban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/)

    const { prefix, max } = config

    // check syntax and length
    if (!code || code[1] !== prefix || cleanIban.length !== max) {
      return error
    }
    // rearrange country code and check digits, and convert chars to ints
    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
      return letter.charCodeAt(0) - 55
    })
    // final check

    return mod97(digits) ? null : error
  }
}

export function email(value) {
  if (!emailRegex.test(value)) return `utils:validate.form_error_email`

  return null
}

export function createPostalCodeValidator(config) {
  return function postalCode(blacklistedDepartments = [], blacklistedPostalCodes = []) {
    const { exclude, excludeFromDepartments } = config

    return value => {
      const safeValue = String(value)
      const filterDep = excludeFromDepartments(blacklistedDepartments)
      const filterPostCode = blacklistedPostalCodes.map(dep => new RegExp(dep))
      const allFilters = [...exclude, ...filterDep, ...filterPostCode]

      if (allFilters.some(reg => reg.test(safeValue))) {
        return `utils:validate.form_error_postal_code_product`
      }

      return null
    }
  }
}

export function createSimplePostalCodeValidator(config) {
  return function simplePostalCode(value) {
    if (!config.format.test(value)) {
      return `utils:validate.form_error_postal_code`
    }

    return null
  }
}

export function createPlaceholder(config) {
  return config.placeHolder || ``
}

export const noopMask = {
  mask: false,
  unmask: x => x,
}

export const cedilleMask = {
  mask: false,
  unmask: value => value.replace(/ç/g, `c`).replace(/Ç/g, `C`),
}

export function createMask(config) {
  return config.mask || noopMask
}

const orderIdRegex = /^\d{1}-\d{11}$/
const customerIdRegex = /^\d{9,10}$/
export function agentCommandId(value) {
  if (!orderIdRegex.test(value) && !customerIdRegex.test(value)) {
    return `utils:validate.form_error_input`
  }

  return null
}

export function validateAgentId(value) {
  if (!/^[0-9a-zA-Z-_]+$/.test(value)) {
    return `utils:validate.form_error_invalid_char`
  }

  return null
}

export function createCustomerIdValidator(config) {
  return function customerId(value) {
    const { regex } = config
    if (value && !regex.test(value)) {
      return `utils:validate.form_error_invalid_customer_id`
    }

    return null
  }
}

export function compose(...validators) {
  return (...props) => {
    let message = null

    for (const validator of validators) {
      message = validator(...props)
      if (message !== null) break
    }

    return message
  }
}
