import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { colors, fontSizes } from "@corefront-shared/styled/theme"
import breakpoint from "styled-components-breakpoint"

const Notice = styled.div`
  display: none;
  position: absolute;
  border: 1px solid ${colors(`helper`)};
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  width: 200px;
  padding: 4px 8px;
  color: ${colors(`grey.g800`)};
  text-align: center;
  background-color: white;
  border-radius: 3px;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: ${fontSizes(`small`)};
  word-wrap: break-word;
  line-height: 1.5;

  ${breakpoint(`medium`)`
    left: calc(100% + 10px);
  `}

  &:before {
    right: 100%;
    top: calc(50% - 8px);
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-width: 8px 8px 8px 0;
    border-right-color: ${colors(`helper`)};
  }
`

const Bubble = styled.span`
  background-color: ${colors(`helper`)};
  width: 26px;
  height: 26px;
  color: white;
  border-radius: 100%;
  border: none;
  box-shadow: none;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: help;

  ${breakpoint(`medium`)`
    margin-left: 10px;
  `}

  &:hover {
    & + ${Notice} {
      display: block;
    }
  }

  &:before {
    content: "i";
  }
`

const Container = styled.div`
  position: relative;
  z-index: 1;

  ${breakpoint(`medium`)`
    display: inline-block;
  `}
`

export function Helper({ help = `` }) {
  if (!help) {
    return null
  }

  return (
    <Container>
      <Bubble />
      <Notice>{help}</Notice>
    </Container>
  )
}

Helper.propTypes = {
  help: PropTypes.node,
}
