import React from "react"
import { fontSizes, useThemeContext } from "@corefront-shared/styled/theme"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Section from "@corefront-shared/components/section.component"
import Didomi from "@corefront-shared/components/grpd/didomi.component"
import { useThirdPartiesContext } from "@corefront-shared/providers/thridParties/thirdParties.provider"

const SimplifiedList = styled.ul`
  & > :not(:last-child):after {
    content: "-";
    padding: 0 5px;
    display: inline-block;
  }
  display: flex;
  list-style: none;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  font-size: ${fontSizes(`small`)};
  color: white;
  justify-content: center;
  margin: 8px 0;
  max-width: 500px;
  ${breakpoint(`small`)`
    max-width: unset;
  `}
`

export const Copyright = styled.div`
  margin-bottom: 15px;
`

const SimplifiedListItem = styled.li`
  text-align: center;
  padding: 5px 0;

  a {
    text-decoration: none;
    color: inherit;
  }
`

const newTab = {
  target: `_blank`,
  rel: `noreferrer`,
}

const Pointer = styled.span`
  cursor: pointer;
`

export default function FooterLinksSection() {
  const { colors } = useThemeContext()
  const { setGRPDReady } = useThirdPartiesContext()

  return (
    <Section bgColor={colors.grey.g800} bgWidth="full">
      <SimplifiedList data-testid="post_footer_link">
        <SimplifiedListItem data-testid="footer_copyright">Ⓒ HomeServe 2021</SimplifiedListItem>
        <SimplifiedListItem>
          <a
            {...newTab}
            href="https://www.homeserve.fr/mentions-legales?lien_interne=Footer"
            data-testid="footer_mentions_legales"
          >
            Mentions légales
          </a>
        </SimplifiedListItem>
        <SimplifiedListItem>
          <a
            {...newTab}
            href="https://www.homeserve.fr/conditions-juridiques?lien_interne=Footer"
            data-testid="footer_conditions_juridiques"
          >
            Conditions juridiques
          </a>
        </SimplifiedListItem>
        <SimplifiedListItem>
          <a
            {...newTab}
            href="https://www.homeserve.fr/politique-protection-donnees-personnelles?lien_interne=Footer"
            data-testid="footer_donnees_personnelles"
          >
            Politique de données personnelles
          </a>
        </SimplifiedListItem>
        <SimplifiedListItem>
          <a
            {...newTab}
            href="https://www.homeserve.fr/politique-des-cookies?lien_interne=Footer"
            data-testid="footer_politique_des_cookies"
          >
            Politique des cookies
          </a>
        </SimplifiedListItem>
        <Didomi apiKey={APP_CONFIG.didomi.apiKey} sdkPath={APP_CONFIG.didomi.url} onReady={setGRPDReady}>
          {({ clickHandler }) => (
            <SimplifiedListItem>
              <Pointer data-testid="footer_gestion_cookies" onClick={clickHandler}>
                Gestion des cookies
              </Pointer>
            </SimplifiedListItem>
          )}
        </Didomi>
      </SimplifiedList>
    </Section>
  )
}
