import React, { useCallback, useMemo } from "react"
import PropType from "prop-types"
import { Formik } from "formik"
import noop from "@corefront-shared/utils/noop"
import { Empty } from "../empty.component"

const defaultPlugin = {
  name: ``,
  Component: Empty,
  props: {},
  isReady: false,
  initialValues: null,
  onSubmit: values => values,
  onSubmitSuccess: noop,
  onSubmitFail: noop,
}

function validateWithTranslations(t, validate, values) {
  const validation = validate(values, t)

  for (const key in validation) {
    const errorMessage = validation[key]

    if (!errorMessage) {
      delete validation[key]
    } else if (typeof errorMessage === `string`) {
      validation[key] = t(errorMessage)
    } else if (Array.isArray(errorMessage)) {
      validation[key] = t(...errorMessage)
    } else {
      validation[key] = errorMessage
    }
  }

  return validation
}

function mergeInitialValues(finalValues, values) {
  if (!values) {
    return finalValues
  }

  let updated = false
  const newValues = { ...finalValues }

  for (const key in newValues) {
    if (values[key] !== `` && values[key] !== null && values[key] !== undefined) {
      updated = true
      newValues[key] = values[key]
    }
  }

  return updated ? newValues : finalValues
}

export default function Form(props) {
  const {
    name,
    captcha,
    focus,
    persist,
    draft,
    valueChanges,
    initialValues,
    validate,
    onSubmit,
    onSubmitSuccess = noop,
    onSubmitFail = noop,
    t = x => x,
    children,
  } = props

  const enhanceValidate = useCallback(values => validateWithTranslations(t, validate, values), [t, validate])

  const plugins = useMemo(() => {
    const all = [captcha, focus, persist, draft, valueChanges].filter(plugin => plugin)
    const allReady = all.every(plugin => plugin.isReady)

    if (allReady) {
      return all.map(plugin => ({ ...defaultPlugin, ...plugin }))
    }

    return []
  }, [captcha, focus, draft, persist, valueChanges])

  const combineInitialValues = useMemo(() => {
    return plugins.reduce((acc, plugin) => mergeInitialValues(acc, plugin.initialValues), initialValues)
  }, [plugins.length, initialValues])

  const combineInitialTouched = useMemo(() => {
    return Object.keys(combineInitialValues).reduce((acc, field) => {
      const initialPostPluginValue = combineInitialValues[field]

      if (initialPostPluginValue !== initialValues[field]) {
        acc[field] = true
      }

      return acc
    }, {})
  }, [combineInitialValues, initialValues])

  const combineInitialErrors = useMemo(() => {
    return Object.keys(initialValues).reduce((acc, field) => {
      acc[field] = null

      return acc
    }, {})
  }, [initialValues])

  const combineOnSubmit = useMemo(() => {
    const combine = plugins.reduce(
      (acc, plugin) => ({
        onSubmit: async (values, actions) => acc.onSubmit(await plugin.onSubmit(values, actions), actions),
        onSubmitSuccess: async (values, actions, submitResult) => {
          await plugin.onSubmitSuccess(values, actions, submitResult)
          await acc.onSubmitSuccess(values, actions, submitResult)
        },
        onSubmitFail: async (values, actions, error) => {
          await plugin.onSubmitFail(values, actions, error)
          await acc.onSubmitFail(values, actions, error)
        },
      }),
      {
        onSubmit,
        onSubmitSuccess,
        onSubmitFail,
      },
    )

    return (values, actions) => {
      combine.onSubmit(values, actions).then(
        res => combine.onSubmitSuccess(values, actions, res),
        error => combine.onSubmitFail(values, actions, error),
      )
    }
  }, [plugins, onSubmit, onSubmitSuccess, onSubmitFail])

  const initialStatus = useMemo(() => {
    const refs = {}

    return {
      fieldRefs: {
        addRef: (field, ref) => {
          refs[field] = ref
        },
        removeRef: field => {
          delete refs[field]
        },
        getRefs: () => refs,
      },
    }
  }, [])

  return (
    <Formik
      validate={enhanceValidate}
      initialValues={combineInitialValues}
      initialTouched={combineInitialTouched}
      initialErrors={combineInitialErrors}
      initialStatus={initialStatus}
      onSubmit={combineOnSubmit}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <form name={name} data-testid={`${name}_form`} onSubmit={handleSubmit}>
          {children}
          {plugins.map(plugin => (
            <plugin.Component key={plugin.name} {...plugin.props} />
          ))}
        </form>
      )}
    </Formik>
  )
}

Form.propTypes = {
  name: PropType.string.isRequired,
  captcha: PropType.shape({
    onSubmit: PropType.func.isRequired,
    onSubmitFail: PropType.func.isRequired,
  }),
  focus: PropType.object,
  persist: PropType.object,
  draft: PropType.object,
  valueChanges: PropType.object,
  validate: PropType.func.isRequired,
  initialValues: PropType.object.isRequired,
  onSubmit: PropType.func,
  onSubmitSuccess: PropType.func,
  onSubmitFail: PropType.func,
  t: PropType.func,
  children: PropType.node.isRequired,
}
