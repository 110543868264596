import React, { memo, useEffect, useState } from "react"
import styled from "styled-components"
import Proptypes from "prop-types"
import { formatPriceMonthly } from "@corefront-shared/utils/format"
import { colors, condition, fontSizes } from "@corefront-shared/styled/theme"
import { usePrice } from "../../utils/product"

const ANIMATION_TIME = 0.5

const PriceWrapperAnimated = styled.div`
  line-height: 0;
  order: ${props => (props.animation ? 1 : 3)};
  display: inline-block;
  font-size: ${props => (props.animation ? fontSizes(`x_large`) : 0)};
  transition: font-size ${ANIMATION_TIME}s;
`

const StandardPriceWrapperAnimated = styled.div`
  order: 2;
  display: inline-block;
  width: auto;
  font-size: ${props => (props.animation ? fontSizes(`regular`) : fontSizes(`x_large`))};
  line-height: ${props => (props.animation ? fontSizes(`regular`) : fontSizes(`x_large`))};
  transition: font-size ${ANIMATION_TIME}s;
`

const AnimatedBold = styled.span`
  position: relative;
  font-weight: 900;
  transition: font-weight ${ANIMATION_TIME}s;

  &:after {
    position: absolute;
    display: ${condition(`animation`, `block`, `none`)};
    content: "";
    left: 0;
    top: 40%;
    right: 0;
    border-top: 2px solid;
    border-color: ${props => props.$color || colors(`primary`)};
    transform: rotate(-20deg) translate(-1px, 0);
    opacity: ${condition(`animation`, `1`, `0`)};
    transition: opacity ${ANIMATION_TIME * 2}s;
  }
`

const Container = styled.div`
  color: ${props => props.color || colors(`primary`)};
  display: flex;
  width: 100%;
  justify-content: left;
  margin: 8px 0px 8px 0px;
  align-items: baseline;
`

const PerMonth = styled.span`
  /* stylelint-disable */
  font-size: 0.58em;
  line-height: 1.3em;
  font-weight: 500;
  margin-left: 4px;
`

const Bold = styled.span`
  line-height: 1em;
  /* stylelint-enable */
  font-weight: ${props => props.fontWeight || 900};
`

const ContainerFlex = styled.div`
  display: flex;
  align-items: baseline;
`

const Instead = styled.span`
  font-size: ${condition(`animation`, `0.8em`, `0em`)};
  /* stylelint-disable */
  line-height: 1em;
  /* stylelint-enable */
  transition: font-size ${ANIMATION_TIME}s;
`

function ProductPrice({ product, color = `` }) {
  const { price, standardPrice, betterPrice } = usePrice(product)
  const [animation, setAnimation] = useState(false)

  if (!product) {
    return null
  }

  useEffect(() => {
    setAnimation(betterPrice)
  }, [betterPrice])

  const formatPrice = formatPriceMonthly
  const recurrence = "mois"

  return (
    <Container color={color} data-testid="product_price">
      <PriceWrapperAnimated animation={animation}>
        <ContainerFlex>
          <Bold>{formatPrice(price)}€</Bold>
          <PerMonth>
            {betterPrice && `TTC`}/{recurrence}*
          </PerMonth>
        </ContainerFlex>
      </PriceWrapperAnimated>
      <StandardPriceWrapperAnimated animation={animation}>
        <ContainerFlex>
          <Instead animation={animation}>&nbsp; au lieu de &nbsp;</Instead>
          <AnimatedBold animation={animation} $color={color}>
            {formatPrice(standardPrice)}€
          </AnimatedBold>
          {!betterPrice && <PerMonth>TTC/{recurrence}*</PerMonth>}
        </ContainerFlex>
      </StandardPriceWrapperAnimated>
    </Container>
  )
}

ProductPrice.propTypes = {
  color: Proptypes.string,
  product: Proptypes.object,
}

export const ProductPriceMemo = memo(ProductPrice)
