import React from "react"
import PropTypes from "prop-types"

export default function Picto({
  viewbox,
  d,
  color = APP_CONFIG.theme.colors.grey.g800,
  size = 100,
  children,
  ...props
}) {
  let content = children

  if (d) {
    content = Array.isArray(d) ? (
      d.map((path, i) => <path d={path} key={i} shapeRendering="auto" />)
    ) : (
      <path fill={color} d={d} shapeRendering="auto" />
    )
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox={viewbox}
      width={size}
      height={size}
      fill={color}
      {...props}
    >
      {content}
    </svg>
  )
}

Picto.propTypes = {
  viewbox: PropTypes.string,
  d: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
}
