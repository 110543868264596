import React from "react"
import Section from "@corefront-shared/components/section.component"
import { colors, fontSizes } from "@corefront-shared/styled/theme"
import LoaderHeader from "./loaderHeader.component"
import PostHeader from "./postHeader.component"
import logo from "../../assets/logos/homeserve.png"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import HeaderPhone from "../phone/headerPhone.component"
import { useAppContext } from "../providers/app.provider"
import { useLazyLogo } from "../image/useLazyImage"
import { isHomeServePartner } from "../../data/api"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
  ${breakpoint(`medium`)`
     padding: 16px 0px;
  `}
`
const HeaderLogo = styled.div`
  display: flex;
  height: 33px;
  ${breakpoint(`medium`)`
    display: flex;
    height: 57px;
  `}
`
const HeaderSeparator = styled.div`
  display: none;

  ${breakpoint(`medium`)`
    display: flex;
    align-self: flex-end;
    margin: 0px 8px;
    font-size: ${fontSizes("x_small")};
  `}
`

const MobileSeparator = styled.div`
  border-left: 1px solid ${colors("grey.g700")};
  margin: 0px 16px;
  display: flex;
  ${breakpoint(`medium`)`
    display: none;
  `}
`

export default function Header() {
  const {
    state: { userData, loadingData },
  } = useAppContext()
  const policy = userData?.policy
  const partnerTel = policy ? policy.partnerLines.commercial : ``
  const [partnerLogoSrc, partnerLogoLoaded] = useLazyLogo(policy?.partner)
  const partnerLogoAlt = policy ? policy?.partnerVerbose : ``

  return (
    <>
      <LoaderHeader loading={loadingData} />
      <Section bgWidth="full">
        <Container>
          <HeaderLogo>
            <img src={logo} alt="HomeServe" data-testid="homeLogo" />
            {policy && !isHomeServePartner(policy) && (
              <>
                <HeaderSeparator>Recommandé par</HeaderSeparator>
                <MobileSeparator />
                {partnerLogoLoaded && <img src={partnerLogoSrc} alt={partnerLogoAlt} data-testid="partner_logo" />}
              </>
            )}
          </HeaderLogo>
          <HeaderPhone phoneNumber={partnerTel} />
        </Container>
      </Section>
      <PostHeader phoneNumber={partnerTel} />
    </>
  )
}
