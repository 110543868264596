import Section from "@corefront-shared/components/section.component"
import React, { useEffect } from "react"
import styled from "styled-components"
import { colors, useThemeContext } from "@corefront-shared/styled/theme"
import breakpoint from "styled-components-breakpoint"
import Truck from "@corefront-shared/components/pictos/truck.component"
import Medal from "@corefront-shared/components/pictos/medal.component"
import Headset from "@corefront-shared/components/pictos/headset.component"
import VerifiedReviews from "../../verifiedReviews/verifiedReviews.component"
import { useThirdPartiesContext } from "@corefront-shared/providers/thridParties/thirdParties.provider"

const ShowDesktop = styled.div`
  display: none;
  padding-bottom: 24px;
  ${breakpoint(`medium`)`
    display: block;
  `}
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: ${colors("grey.g300")};
  justify-content: space-around;
`

const Text = styled.div`
  padding-top: 16px;
  color: ${colors("grey.g900")};
`
const Group = styled.div`
  text-align: center;
  width: 160px;
`

export default function FourStepSection() {
  const { colors: themeColors } = useThemeContext()
  const { load, isLoaded } = useThirdPartiesContext()
  const isVerifiedReviewsReady = isLoaded(`verifiedReviews`)

  useEffect(() => {
    load(`verifiedReviews`)
  }, [])

  return (
    <ShowDesktop>
      <Section padding>
        <Wrapper>
          {(isVerifiedReviewsReady || process.env.ENVIRONMENT === `mock`) && (
            <Group>
              <VerifiedReviews />
            </Group>
          )}
          <Group>
            <Truck size={60} color={themeColors.primary} />
            <Text>2h Pour organiser l’intervention</Text>
          </Group>
          <Group>
            <Headset size={60} color={themeColors.primary} />
            <Text>24h/7j nos conseillers vous accompagnent</Text>
          </Group>
          <Group>
            <Medal size={60} color={themeColors.primary} />
            <Text>Réparation garantie un an !</Text>
          </Group>
        </Wrapper>
      </Section>
    </ShowDesktop>
  )
}
