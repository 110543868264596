import React from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import { colors } from "@corefront-shared/styled/theme"

const slowProgression = keyframes`
  0% { width: 3% }
  100% { width: 50% }
`

const fastProgression = keyframes`
  0% { width: 50% }
  100% { width: 100% }
`

const gradientAnimation = keyframes`
  0% { background-position: 0% }
  100% { background-position: 100% }
`

const LoaderProgress = styled.div`
  z-index: 1;
  position: fixed;
  animation: ${props => (props.isLoading ? slowProgression : fastProgression)} ease-out;
  animation-duration: ${props => (props.isLoading ? `10s` : `1s`)};
`

const Gradient = styled.div`
  height: 4px;
  background: linear-gradient(to right, ${colors("primary")}, rgba(241, 152, 142, 1));
  background-size: 600% 100%;
  animation: ${gradientAnimation} 1s linear infinite;
  animation-direction: alternate;
  transition-timing-function: ease;
`

export default function LoaderHeader({ loading = true }) {
  return (
    <LoaderProgress
      isLoading={loading}
      data-testid={loading ? "header_progress_bar_visible" : "header_progress_bar_hidden"}
    >
      <Gradient />
    </LoaderProgress>
  )
}

LoaderHeader.propTypes = {
  loading: PropTypes.bool,
}
