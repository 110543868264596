import React, { memo } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useThemeContext } from "@corefront-shared/styled/theme"

const FONT_TYPE = `font/otf`

function HeadFonts({ basePath = `` }) {
  const theme = useThemeContext()

  function fontUrl(family, weight) {
    return `${basePath}/fonts/${family}-${weight}.otf`
  }

  function fontFace(family, weight) {
    return `
    @font-face {
      font-family: ${family};
      src: url('${fontUrl(family, weight)}') format('truetype');
      font-weight: ${weight};
      font-display: fallback;
    }
  `
  }

  const fontFamilyWeight = Object.keys(theme.fonts)
    // @todo remove this condition when fontfamily and font size will be separated
    .filter(fontKey => fontKey !== `sizes`)
    .flatMap(fontKey => {
      const { family, weight: weights } = theme.fonts[fontKey]

      return weights.map(weight => [family, weight])
    })

  const fontFaces = fontFamilyWeight.reduce((_fontFaces, [family, weight]) => _fontFaces + fontFace(family, weight), ``)

  const fontUrls = fontFamilyWeight.map(([family, weight]) => fontUrl(family, weight))

  return (
    <Helmet>
      <style>{fontFaces}</style>
      {fontUrls.map(url => (
        <link as="font" rel="preload" href={url} key={url} type={FONT_TYPE} crossOrigin="anonymous" />
      ))}
    </Helmet>
  )
}

HeadFonts.propTypes = {
  basePath: PropTypes.string,
}

export default memo(HeadFonts)
