import React from "react"
import styled from "styled-components"
import { colors } from "@corefront-shared/styled/theme"
import FooterEscdaSection from "./footerEscdaSection.component"
import FooterLinksSection from "./footerLinksSection.component"
import { FooterFrenchSection } from "./footerFrenchSection.component"
import { useAppContext } from "../providers/app.provider"

const Separator = styled.div`
  border-top: 1px solid ${colors("grey.g500")};
`

export default function Footer() {
  const {
    state: { userData },
  } = useAppContext()
  const policy = userData?.policy
  const partner = policy
    ? {
        tel: policy.partnerLines.commercial,
      }
    : null

  return (
    <>
      <Separator />
      <FooterFrenchSection phoneNumber={partner?.tel} />
      <FooterEscdaSection />
      <FooterLinksSection />
    </>
  )
}
