import React from "react"
import ReactDOM from "react-dom"
import Layout from "./layouts/default.layout"
import "./translations/i18n"
import { sentryInit } from "@corefront-shared/utils/sentry"

if (process.env.ENVIRONMENT === `mock`) {
  require(`@corefront-shared/utils/mocks/mockdate`).mockDateFromSession()
}

if (process.env.ENVIRONMENT === `mock` || process.env.ENVIRONMENT === `preprod` || process.env.ENVIRONMENT === `prod`) {
  sentryInit(APP_CONFIG.sentry.dsn, process.env.ENVIRONMENT, `hermes-${process.env.VERSION}`)
}

ReactDOM.render(
  <React.StrictMode>
    <Layout />
  </React.StrictMode>,
  document.getElementById("root"),
)
