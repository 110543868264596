import React, { forwardRef } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import darken from "polished/lib/color/darken"
import { colors, condition, fontSizes } from "@corefront-shared/styled/theme"
import { flexCenter } from "@corefront-shared/styled/styles"

const StyledButton = styled.button`
  ${flexCenter};
  text-decoration: none;
  outline: 0;
  white-space: normal;
  border-style: solid;
  border-width: 2px;
  border-radius: 30px;
  padding: 0 24px;
  cursor: pointer;
  line-height: 1.2;
  font-size: ${fontSizes(`regular`)};
  height: ${condition(`$major`, 45, 40)}px;
  font-weight: ${condition(`$major`, 900, 500)};

  ${props =>
    props.$singleLine &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}

  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  svg,
  path {
    width: 12px;
    height: 28px;
    margin-right: 4px;
    fill: ${props => (props.$ghost ? props.$backgroundColor : props.$textColor)}!important;
    transition: fill 0.1s ease-in-out;
  }

  color: ${props => (props.$ghost ? props.$backgroundColor : props.$textColor)};
  border-color: ${props => props.$backgroundColor};
  background-color: ${props => (props.$ghost ? `transparent` : props.$backgroundColor)};

  :hover {
    color: ${props => (props.$ghost ? props.$textColor : props.$backgroundColor)};
    border-color: ${props => props.$backgroundColor};
    background-color: ${props => (props.$ghost ? props.$backgroundColor : `transparent`)};

    svg,
    path {
      fill: ${props => (props.$ghost ? props.$textColor : props.$backgroundColor)} !important;
    }
  }

  &,
  * {
    vertical-align: middle;
  }

  :disabled,
  [disabled],
  :disabled:hover,
  [disabled]:hover {
    cursor: not-allowed;
    color: ${props => darken(0.2, typeof props.$textColor === `function` ? props.$textColor(props) : props.$textColor)};
    border-color: ${props =>
      darken(0.2, typeof props.$textColor === `function` ? props.$textColor(props) : props.$textColor)};
    background-color: ${props =>
      darken(
        0.2,
        typeof props.$backgroundColor === `function` ? props.$backgroundColor(props) : props.$backgroundColor,
      )};
  }
`

const FirstLetterUppercase = styled.span`
  display: inline;

  &::first-letter {
    text-transform: uppercase;
  }
`

export const Button = forwardRef(
  (
    {
      as = `button`,
      type = `button`,
      textColor = `white`,
      backgroundColor = colors(`primary`),
      major = false,
      ghost = false,
      singleLine = false,
      icon,
      children,
      ...otherProps
    },
    ref,
  ) => {
    return (
      <StyledButton
        {...otherProps}
        ref={ref}
        role="button"
        as={as}
        type={as === `button` ? type : undefined}
        $textColor={textColor}
        $backgroundColor={backgroundColor}
        $major={major}
        $ghost={ghost}
        $singleLine={singleLine}
      >
        {icon}
        <FirstLetterUppercase>{children}</FirstLetterUppercase>
      </StyledButton>
    )
  },
)

Button.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  type: PropTypes.oneOf([`button`, `submit`]),
  textColor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  backgroundColor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  major: PropTypes.bool,
  ghost: PropTypes.bool,
  singleLine: PropTypes.bool,
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
}
