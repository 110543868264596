import React from "react"
import Picto from "@corefront-shared/components/picto.component"

export default function Headset(props) {
  return (
    <Picto
      viewBox="0 0 28.54 34.38"
      d={[
        `M21.19,12.47a7.4,7.4,0,0,0,2.4.55h.07a.65.65,0,0,0,.66-.59h0v-.07a.67.67,0,0,0-.59-.67h0a6.39,6.39,0,0,1-2-.43,2.86,2.86,0,0,1-1.51-1.91l-.1-.48L20,8.35,19.2,9A18.45,18.45,0,0,1,8.33,12.51a17,17,0,0,1-3.11-.28H5.1a.66.66,0,0,0-.65.55v.12a.67.67,0,0,0,.54.66H5a18.5,18.5,0,0,0,3.33.3,19.78,19.78,0,0,0,11-3.33A4.17,4.17,0,0,0,21.19,12.47Z`,
        `M4.45 12.77 4.45 12.77 4.45 12.77 4.45 12.77z`,
        `M27.33,14.09v-1a13.06,13.06,0,1,0-26.12,0v1a2.3,2.3,0,0,0-1.21,2,47.13,47.13,0,0,0,.54,5.56,2.35,2.35,0,0,0,2.52,1.89,1.92,1.92,0,0,0,1.74-2.3,47.13,47.13,0,0,1-.54-5.56A1.93,1.93,0,0,0,2.7,13.83v-.77a11.57,11.57,0,1,1,23.13,0v.77a2,2,0,0,0-1.56,1.89,43.24,43.24,0,0,1-.54,5.56A1.94,1.94,0,0,0,25,23.47a16.93,16.93,0,0,1-1.44,3.86,9.41,9.41,0,0,1-6.42,5.22,1.07,1.07,0,0,0-.83-.4H14.41a1.12,1.12,0,0,0,0,2.23H16.3a1.09,1.09,0,0,0,1.05-.85,10.5,10.5,0,0,0,7.1-5.76A18.62,18.62,0,0,0,26,23.57a2.35,2.35,0,0,0,2-1.88,47.13,47.13,0,0,0,.54-5.56A2.3,2.3,0,0,0,27.33,14.09Z`,
        `M15.66,27.08h0a7.89,7.89,0,0,0,2.63-.66,8.38,8.38,0,0,0,1-.53.8.8,0,0,0,.33-.72,1.18,1.18,0,0,0-.08-.31.66.66,0,0,0-.63-.4.72.72,0,0,0-.27.09,6,6,0,0,1-.83.45,6.59,6.59,0,0,1-2.24.56,6.76,6.76,0,0,1-1.85-.13,6.38,6.38,0,0,1-2.15-.9.58.58,0,0,0-.37-.1.62.62,0,0,0-.53.39.86.86,0,0,0,.27,1,7.75,7.75,0,0,0,2.57,1.07A7.89,7.89,0,0,0,15.66,27.08Z`,
      ]}
      {...props}
    />
  )
}
