import React, { useState } from "react"
import Proptypes from "prop-types"
import WebCallbackFormStatic from "./webCallbackForm.component"
import WebCallbackModal from "@corefront-shared/components/webCallback/webCallbackModal.component"
import noop from "@corefront-shared/utils/noop"
import { useThemeContext } from "@corefront-shared/styled/theme"
import { useTranslation } from "react-i18next"
import standardiste from "../../assets/image/wcb_standardiste.png"

export default function WebCallbackModalStatic({ onClose, partnerCode, schedules }) {
  const { colors } = useThemeContext()
  const [answer, setAnswer] = useState(null)
  const { t } = useTranslation()

  function onSubmit(status) {
    setAnswer(status)
  }

  function answerIS(status) {
    return answer === status
  }

  return (
    <WebCallbackModal
      t={t}
      addEventTracking={noop}
      backgroundColor={colors.info}
      hasError={!answer || answerIS(`error`)}
      headsetColor={"black"}
      onClose={onClose}
      schedules={schedules}
      image={<img src={standardiste} alt="wcb" />}
      data-testid="webcallback_modal"
    >
      <WebCallbackFormStatic onSubmit={onSubmit} errorBack={answerIS(`error`)} partnerCode={partnerCode} />
    </WebCallbackModal>
  )
}

WebCallbackModalStatic.propTypes = {
  onClose: Proptypes.func.isRequired,
  partnerCode: Proptypes.string.isRequired,
  schedules: Proptypes.string.isRequired,
}
