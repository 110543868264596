import React from "react"
import Proptypes from "prop-types"
import styled from "styled-components"

import { useAppContext } from "../providers/app.provider"
import ProgressStep from "./progressStep.component"
import Section from "@corefront-shared/components/section.component"

const Separator = styled.div`
  content: "";
  border-top: 2px solid;
  width: 10%;
  margin: 18px 10px 0px;
  border-color: ${props => (props.isPassed ? "red" : "grey")};
`

const StepContainer = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: center;
  line-height: 1;
`

const noop = () => {}

const Progress = ({ disabled = false }) => {
  const {
    state: { currentStep, steps, availableSteps },
    setCurrentStep,
  } = useAppContext()

  return (
    <Section padding>
      <StepContainer data-testid="progress">
        {steps.map((step, i, arr) => {
          const isAvailable = availableSteps.length ? availableSteps.indexOf(i) >= 0 : true
          const isPassed = currentStep > i || currentStep === -1

          return (
            <React.Fragment key={i}>
              <ProgressStep
                step={i + 1}
                title={step.title}
                isCurrent={i === currentStep}
                isAvailable={isAvailable}
                setStep={isAvailable && !disabled ? () => setCurrentStep(i) : noop}
              />
              {i + 1 !== arr.length && <Separator isPassed={isPassed} />}
            </React.Fragment>
          )
        })}
      </StepContainer>
    </Section>
  )
}

Progress.propTypes = {
  disabled: Proptypes.bool,
}

export default Progress
