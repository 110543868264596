import React, { useEffect, useRef } from "react"
import { Field as FormikField, useFormikContext } from "formik"
import PropTypes from "prop-types"
import uniqueId from "@corefront-shared/utils/uniqueId"

export default function Field({ hide, name, ...props }) {
  const { values, status } = useFormikContext()
  const id = useRef()
  const ref = useRef()
  const statusRef = useRef()
  statusRef.current = status

  if (!id.current) {
    id.current = uniqueId(`field-`)
  }

  useEffect(() => {
    const { fieldRefs } = statusRef.current
    fieldRefs.addRef(name, ref)

    return () => {
      fieldRefs.removeRef(name, ref)
    }
  }, [])

  if (hide && hide(values)) {
    return null
  }

  return <FormikField id={id.current} name={name} innerRef={ref} {...props} />
}

Field.propTypes = {
  hide: PropTypes.func,
  name: PropTypes.string.isRequired,
}
