import React from "react"
import PropTypes from "prop-types"
import { BrowserRouter, Route } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import { QueryParamProvider } from "use-query-params"
import ThirdPartiesProvider from "@corefront-shared/providers/thridParties/thirdParties.provider"
import { AppProvider } from "../components/providers/app.provider"
import { createStorage, sessionStorage, StorageProvider } from "@homeserve/react-storage-provider"

const thirdPartiesConfig = {
  abtasty: {
    onMount: true,
    scriptUrl: APP_CONFIG.abtasty.url,
  },
  captcha: {
    scriptUrl: APP_CONFIG.captcha.url,
  },
  didomi: {
    scriptUrl: APP_CONFIG.didomi.url + `didomi/loader.js`,
  },
  stripe: {
    scriptUrl: APP_CONFIG.stripe.url,
  },
  verifiedReviews: {
    scriptUrl: APP_CONFIG.verifiedReviews.url,
  },
}

const storage = createStorage(sessionStorage(APP_CONFIG.storage.sessionStorageKeys))

export default function Provider({ children }) {
  return (
    <StorageProvider storage={storage}>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ThemeProvider theme={APP_CONFIG.theme}>
            <ThirdPartiesProvider config={thirdPartiesConfig}>
              <AppProvider>{children}</AppProvider>
            </ThirdPartiesProvider>
          </ThemeProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </StorageProvider>
  )
}

Provider.propTypes = {
  children: PropTypes.any.isRequired,
}
