import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { fontSizes, useThemeContext } from "@corefront-shared/styled/theme"
import Section from "@corefront-shared/components/section.component"
import breakpoint from "styled-components-breakpoint"
import Smartphone from "@corefront-shared/components/pictos/smartphone.component"
import { formatPhone } from "@corefront-shared/utils/format"

const Separator = styled.div`
  border-bottom: 1px solid white;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  color: white;
  ${breakpoint(`medium`)`
    display: none;
  `}
`

const Notice = styled.span`
  font-size: ${fontSizes(`x_small`)};
`

const Number = styled.span`
  margin: 0px 8px 0px 0px;
  font-weight: 900;
`

const Picto = styled.span`
  transform: rotate(90deg);
`

const PhoneCta = styled.a`
  text-decoration: none;
  color: white;
`

export default function PostHeader({ phoneNumber }) {
  const theme = useThemeContext()

  return (
    <>
      <Section bgColor={theme.colors.primary} bgWidth="full">
        <Container>
          <Picto>
            <Smartphone size={25} color="white" />
          </Picto>
          <Number>
            <PhoneCta primary>{formatPhone(phoneNumber)}</PhoneCta>
          </Number>
          {phoneNumber.startsWith("08") && <Notice>Service gratuit + coût de l'appel</Notice>}
        </Container>
      </Section>
      <Separator />
    </>
  )
}

PostHeader.propTypes = {
  phoneNumber: PropTypes.string,
}
